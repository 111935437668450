<ion-grid>
  <ion-segment #pageView value="past" [disabled]="loadingInitialData" (ionChange)="getReservations($event)" >
    <ion-segment-button value="past">
      <ion-label>Past</ion-label>
    </ion-segment-button>
    <ion-segment-button value="inhouse">
      <ion-label>In-house</ion-label>
    </ion-segment-button>
    <ion-segment-button value="upcoming">
      <ion-label>Upcoming</ion-label>
    </ion-segment-button>
  </ion-segment>

  <ion-row>
    <ion-col>
        <ng-container *ngIf="!loadingInitialData; else loader">
          <ng-container *ngIf="reservationList?.length; else noData">
            <ion-row>
              <ion-col size="12" size-md="6" class="ion-margin-bottom" *ngFor="let reservation of reservationList">
                <app-reservation-list-item [reservation]="reservation"
                  (reservationClick)="openReservation(reservation?.reservationNumber)"></app-reservation-list-item>
              </ion-col>
              <ion-infinite-scroll [disabled]="(totalReservations <= reservationList?.length)" (ionInfinite)="loadMoreReservations( $event )">
              <ion-infinite-scroll-content loadingText="Loading More Reservations..."></ion-infinite-scroll-content>
              </ion-infinite-scroll>
            </ion-row>
          </ng-container>
          <ng-template #noData>
            <ion-list class="ion-text-center">
              <ion-text>No Reservations Found.</ion-text>
            </ion-list>
          </ng-template>
        </ng-container>
        <ng-template #loader>
          <ion-card class="ion-text-center ion-padding-vertical">
            <ion-spinner></ion-spinner>
          </ion-card>
        </ng-template>
    </ion-col>
  </ion-row>
</ion-grid>
