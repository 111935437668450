import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent implements OnInit {
  @Input() maxContent: boolean = true; 
  @Input() success: boolean = false;  
  @Input() className: string = '';  
  @Input() warningText: string = 'Your Membership is In-Active. Please contact Sales Team.';
  @Input() warningIcon: string = "alert-circle-outline";

  constructor() { }

  ngOnInit() {}

}
