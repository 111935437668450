import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { RESERVATION_STATUS } from 'src/app/core/enum/reservation';
import { Reservation } from 'src/app/core/models/reservation';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
    selector: 'app-reservation-list-item',
    templateUrl: './reservation-list-item.component.html',
    styleUrls: ['./reservation-list-item.component.scss'],
})
export class ReservationListItemComponent implements OnInit {
    @Input() reservation: Reservation;
    @Output() reservationClick: EventEmitter<any> = new EventEmitter();

    @Output() checkinClick: EventEmitter<any> = new EventEmitter();

    reservationDailyList: any;
    public user: User;

    constructor(public utils: UtilService, public authService: AuthService) {
        this.user = this.authService.getUserData();
    }

    public get showCheckin(): boolean {
        const isMember = this.user?.roles?.includes('member') && this.user?.memberId && this.user?.ownerNumber;
        const isValidCheckin = moment().startOf('day').diff(moment(this.reservation.checkin,'MMM DD, YYYY').startOf('day'),'days')>=0;
        const isValidCheckout = moment().startOf('day').diff(moment(this.reservation.checkout,'MMM DD, YYYY').startOf('day'),'days')<0;
        return !isMember && isValidCheckin && isValidCheckout && this.reservation?.status===RESERVATION_STATUS.PENDING;
    }

    ngOnInit() {}

    public dispatchReservationClick(reservationNumber: any) {
        this.reservationClick.emit(reservationNumber);
    }

    public dispatchCheckinClick(reservationNumber: any) {
        this.checkinClick.emit(reservationNumber);
    }
}
