import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  Renderer2,
} from '@angular/core';
import { UtilService } from '../services/util.service';

@Directive({
  selector: '[appDragDrop]',
})
export class DragDropDirective {
  @Output() droppedFile: EventEmitter<File[]> = new EventEmitter();
  constructor(private el: ElementRef, private renderer: Renderer2, private utilService: UtilService) {}

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.setStyle(this.el.nativeElement, 'backgound', '#999');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.setStyle(this.el.nativeElement, 'backgound', '#eee');
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.setStyle(this.el.nativeElement, 'backgound', '#eee');
    const files = [];
    for (const file of Array.from(evt.dataTransfer.files)) {
      if (file.type === 'image/png' || file.type === 'image/jpeg'){
        files.push(file);
      }else{
        this.utilService.showToast('Please upload a valid file format. Only .jpg/.jpeg and .png images are allowed');
      }
    }
    this.droppedFile.emit(Array.from(files));
  }
}
