<ion-card class="storage-card" (click)="cardClick()">
  <ion-card-header>
    <ion-card-title class="flex ion-justify-content-between">
      {{header}}
      <ion-label class="storage_move__action">
        <ion-icon *ngIf="showEdit" name="open-outline" (click)="editClick()" ></ion-icon>
        <ion-icon *ngIf="showDelete" color="danger" name="trash-outline" (click)="onDeleteClicked()"></ion-icon>
      </ion-label>
    </ion-card-title>
  </ion-card-header>
  <ion-card-content *ngIf="bodyJson.length || body">
    <ion-label *ngFor="let data of bodyJson">
      {{data?.key}}
      <ion-text> {{data?.value}}</ion-text>
    </ion-label>
    <ion-label>
      {{body}}
    </ion-label>
  </ion-card-content>
  <ng-content></ng-content>
    <ion-footer *ngIf="footer.length">
      <ion-row>
        <ion-col *ngFor="let data of footer">
          <ion-text>{{data?.key}}</ion-text> {{data?.value}}
        </ion-col>
      </ion-row>
    </ion-footer>
</ion-card>
