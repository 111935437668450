import { Injectable } from '@angular/core';
import {RealmService} from './realm.service';
import {BehaviorSubject} from 'rxjs';
import { Storage } from 'src/app/core/models/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {

  public occupancyStats: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private realmService: RealmService) { }

  public async loadOccupancy(): Promise<any> {
    const result = await this.realmService.realmApp?.currentUser?.functions.statsStorageSites();
    this.occupancyStats.next(result);
  }

  public async searchStorageSites(searchString: string): Promise<any> {
    return this.realmService.realmApp?.currentUser?.functions.searchStorageSites(searchString);
  }

  public async getStorage(id: string): Promise<any> {
    return this.realmService.realmApp?.currentUser?.functions.readStorageSite(id);
  }

  public async updateStorage(storage: Storage) {
    return this.realmService.realmApp?.currentUser?.functions.updateStorageSite(storage);
  }

  public async createStorage(storage: Storage) {
    return this.realmService.realmApp?.currentUser?.functions.createStorageSite(storage);
  }

  public async checkStorage(memberNumber: string) {
    return this.realmService.realmApp?.currentUser?.functions.checkStorage(memberNumber);
  }

}
