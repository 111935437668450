import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() id: string;
  @Input() caption: string;
  @Input() searchPlaceHolder = 'Search...';
  @Input() showBack = false;
  @Input() showSearch = false;
  @Input() defaultRoute: string;
  @Output() searchText: EventEmitter<string> = new EventEmitter();

  public search = false;
  public user: User;

  constructor( private authService: AuthService) { }

  ngOnInit() {
    this.user = this.authService.getUserData();
    if( this.user?.roles.includes('member') && this.user?.memberId && this.user?.ownerNumber ){
      this.defaultRoute = this.defaultRoute ? this.defaultRoute : '/member-dashboard';
    } else if( !this.user ){
      this.logout();
    } else{
      this.defaultRoute = this.defaultRoute ? this.defaultRoute : '/dashboard';
    }
  }

  public searchToggle(){
    this.search = !this.search;
  }

  public async logout() {
    const ionPopOver: any = (document.getElementById('logOutMenu'));
    await ionPopOver?.dismiss();
    this.authService.logout();
  }

}
