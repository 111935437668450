import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ConfirmationComponent } from './core/components/common-components/confirmation/confirmation.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleAccessGuard } from './core/guards/role-access.guard';
import { LoginGuard } from './core/guards/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: { page: 'dashboard' },
    loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'member-dashboard',
    canActivate: [AuthGuard],
    data: { page: 'member-dashboard' },
    loadChildren: () => import('./features/member-dashboard/member-dashboard.module').then((m) => m.MemberDashboardPageModule),
  },
  {
    path: 'reservations',
    canActivate: [RoleAccessGuard],
    data: { action: 'RESERVATION-READ' },
    loadChildren: () => import('./features/reservations/reservations.module').then((m) => m.ReservationsPageModule),
  },
  {
    path: 'storage',
    canActivate: [RoleAccessGuard],
    data: { action: 'STORAGE-READ' },
    loadChildren: () => import('./features/storage/storage.module').then((m) => m.StoragePageModule),
  },
  {
    path: 'maintenance',
    canActivate: [RoleAccessGuard],
    data: { action: 'MAINTENANCE-READ' },
    loadChildren: () => import('./features/maintenance/maintenance.module').then((m) => m.MaintenancePageModule),
  },
  {
    path: 'housekeeping',
    canActivate: [RoleAccessGuard],
    data: { action: 'HOUSEKEEPING-READ' },
    loadChildren: () => import('./features/housekeeping/housekeeping.module').then((m) => m.HousekeepingPageModule),
  },
  {
    path: 'sales',
    canActivate: [RoleAccessGuard],
    data: { action: 'SALES-READ' },
    loadChildren: () => import('./features/sales/sales.module').then((m) => m.SalesPageModule),
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/reports/reports.module').then((m) => m.ReportsPageModule),
  },
  {
    path: 'grounds',
    canActivate: [RoleAccessGuard],
    data: { action: 'GROUNDS-READ' },
    loadChildren: () => import('./features/grounds/grounds.module').then((m) => m.GroundsPageModule),
  },
  {
    path: 'members',
    canActivate: [RoleAccessGuard],
    data: { action: 'MEMBER_SERVICE-UPDATE_DETAILS' },
    loadChildren: () => import('./features/members/members.module').then((m) => m.MembersPageModule),
  },
  {
    path: 'site-manager',
    canActivate: [RoleAccessGuard],
    data: { action: 'SITE-READ' },
    loadChildren: () => import('./features/site-manager/site-manager.module').then((m) => m.SiteManagerPageModule),
  },
  {
    path: 'rules-manager',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/rules-manager/rules-manager.module').then((m) => m.RulesManagerPageModule),
  },
  {
    path: 'security',
    canActivate: [RoleAccessGuard],
    loadChildren: () => import('./features/security/security.module').then((m) => m.SecurityPageModule),
  },
  {
    path: 'ticket-editor',
    canActivate: [RoleAccessGuard],
    loadChildren: () => import('./features/ticket-editor/ticket-editor.module').then((m) => m.TicketEditorPageModule),
  },
  {
    path: 'ticket-editor/:id',
    canActivate: [RoleAccessGuard],
    loadChildren: () => import('./features/ticket-editor/ticket-editor.module').then((m) => m.TicketEditorPageModule),
  },
  {
    path: 'storage-editor',
    canActivate: [RoleAccessGuard],
    data: { action: 'STORAGE-UPDATE' },
    loadChildren: () => import('./features/settings/storage-manager/storage-editor/storage-editor.module').then(
      m => m.StorageEditorPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'inHouse',
    canActivate: [RoleAccessGuard],
    data: { action: 'INHOUSE-BILL' },
    loadChildren: () => import('./features/in-house/in-house.module').then((m) => m.InHousePageModule),
  },
  {
    path: 'settings',
    canActivate: [RoleAccessGuard],
    data: { action: 'ADMIN-ALL' },
    loadChildren: () => import('./features/settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'confirm-email',
    component: ConfirmationComponent,
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./features/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'paymentConfirmation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/payment-confirmation/payment-confirmation.module').then(m => m.PaymentConfirmationPageModule),
  },
  {
    path: 'mailboxes',
    canActivate: [AuthGuard, RoleAccessGuard],
    data: { action: 'MAILROOM-LIST' },
    loadChildren: () => import('./features/mailboxes/mailboxes.module').then(m => m.MailboxesPageModule),
  },
  {
    path: 'signup',
    data: { action: 'signup' },
    loadChildren: () => import('./features/signup/signup.module').then(m => m.SignupPageModule),
    canActivate: [LoginGuard],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
