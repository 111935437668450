<ion-header class="header flex ion-align-items-center" [ngClass]="{'header--nosearch': !showSearch}">
  <ion-menu-button *ngIf="!showBack" color="primary"></ion-menu-button>


  <ion-title class="h2 flex ion-align-items-center" [ngClass]="{'is-back': showBack}" color="black">
    <ion-back-button *ngIf="showBack" [defaultHref]="defaultRoute" ></ion-back-button>
    {{caption}}
   </ion-title>

  <ion-searchbar *ngIf="showSearch" #searchBar [placeholder]="searchPlaceHolder" appRestrictSpace restrictValue=" (\[])" [debounce]="500" (ionChange)="searchText.next(searchBar.value)" [ngClass]="{'is-active': search}"></ion-searchbar>

  <ion-icon *ngIf="showSearch" class="header__search" (click)="searchToggle()" [name]="search ? 'close-outline': 'search-outline'"></ion-icon>

<!--  <ion-label class="header__notification">-->
<!--      <ion-img src="assets/images/bell.svg"></ion-img>-->
<!--      <ion-text class="flex ion-justify-content-center" color="white">2</ion-text>-->
<!--  </ion-label>-->

  <ion-card class="header__user flex ion-align-items-center" lines="none" [id]="'admin-menu-button'+id">
      <ion-avatar>
        <img [alt]="user?.first+' '+user?.last" src="assets/images/avatar.svg" />
      </ion-avatar>
      <ion-label color="black">{{user?.first}} {{user?.last}}</ion-label>
      <ion-img src="assets/images/angle-down.svg"></ion-img>
  </ion-card>

  <ion-popover id="logOutMenu" [trigger]="'admin-menu-button'+id">
      <ng-template>
          <ion-content>
              <ion-list>
                  <ion-item (click)="logout()" [button]="true" [detail]="false" lines="none">
                      <ion-label>Logout</ion-label>
                  </ion-item>
              </ion-list>
          </ion-content>
      </ng-template>
  </ion-popover>
</ion-header>
