import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
    selector: '[numberOnly]',
})
export class NumberOnlyDirective {
    @Input() formControl: FormControl;
    @Input() hasDecimal = false;
    @Input() limitAfterDecimal: number;
    @Input() hasNegative = false;
    @Input() formatType = '';
    inputElement: HTMLInputElement;
    private navigationKeys = [
        'Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'Clear', 'Copy', 'Paste'
    ];

    constructor(public el: ElementRef) {
        this.inputElement = el.nativeElement;
    }

    @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
        const inputData = this.inputElement.value?.toString() || '';
        if (
            // Allow: Delete, Backspace, Tab, Escape, Enter, etc
            this.navigationKeys.indexOf(e.key) > -1 ||
            (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
            (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
            (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
            (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
            (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
            (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
            (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
            (e.key === 'x' && e.metaKey === true) || // Cmd+X (Mac)
            (e.key === '-' && this.hasNegative && !inputData) || // allow Negative
            (e.key === '.' && this.hasDecimal && inputData.length && inputData.indexOf('.')<0) // allow decimal
        ) {
            return; // let it happen, don't do anything
        }
        // Ensure that it is a number and stop the keypress
        if (e.key === ' ' || isNaN(Number(e.key)) ||
            (this.hasDecimal && this.limitAfterDecimal && inputData.indexOf('.')>=0 &&
                (inputData.length - inputData.indexOf('.') - 1 >= this.limitAfterDecimal))) {
            e.preventDefault();
            e.stopPropagation();
        }
        if(this.formatType === 'phone'){
            if(inputData.search(/^[\d-]+$/g) === -1){
                const maskedVal = inputData.replace(/[^0-9-]/g,'');
                this.inputElement.value = maskedVal;
                return;
            }
            const enteredValue = inputData.replace(/\D/gi, '');
            const value = enteredValue.substring(0,10);
            const str = value.replace(/(.{3})(.{3})(.{3})/g,'$1-$2-$3');
            this.inputElement.value = str;
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        let pastedInput ='';
        if(!this.hasDecimal && !this.hasNegative){

            if(this.formatType === 'phone'){
                this.inputElement.value = '';
                let newValue = event.clipboardData.getData('text/plain');
                const enteredValue = newValue.replace(/\D/g, '');
                const value = enteredValue.substring(0,10);
                const str = value.replace(/(.{3})(.{3})(.{4})/g,'$1-$2-$3');
                pastedInput = str;
                this.inputElement.value = '';
                document.execCommand('insertText', false, pastedInput);
                return;
            }else{
                pastedInput = event.clipboardData.getData('text/plain').replace(/\D/g, ''); // get a digit-only string
            }
        }else{
            const text =  event.clipboardData.getData('text/plain').replace(/[^\d.-]/g, '');
            if(this.hasNegative){
                pastedInput= parseFloat(text).toString();
            }else{
                pastedInput= parseFloat(text)<0? (0- parseFloat(text)).toString():parseFloat(text).toString();
            }

            if(this.hasDecimal && text.indexOf('.')>0){
                if(this.limitAfterDecimal){
                    pastedInput= parseFloat(pastedInput).toFixed(this.limitAfterDecimal);
                }else{
                    pastedInput= parseFloat(pastedInput).toString();
                }
            }else{
                pastedInput= parseInt(pastedInput,10).toString();
            }
        }
        pastedInput = isNaN(Number(pastedInput)) ? '' : pastedInput;
        this.inputElement.value = '';
        document.execCommand('insertText', false, pastedInput);
    }
    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        event.preventDefault();
        let pastedInput ='';
        if(!this.hasDecimal && !this.hasNegative){
            pastedInput = event.dataTransfer.getData('text/plain').replace(/\D/g, ''); // get a digit-only string
        }else{
            const text =  event.dataTransfer.getData('text/plain').replace(/[^\d.-]/g, '');
            if(this.hasNegative){
                pastedInput= parseFloat(text).toString();
            }else{
                pastedInput= parseFloat(text)<0? (0- parseFloat(text)).toString():parseFloat(text).toString();
            }

            if(this.hasDecimal && text.indexOf('.')>0){
                if(this.limitAfterDecimal){
                    pastedInput= parseFloat(pastedInput).toFixed(this.limitAfterDecimal);
                }else{
                    pastedInput= parseFloat(pastedInput).toString();
                }
            }else{
                pastedInput= parseInt(pastedInput,10).toString();
            }
        }
        this.inputElement.focus();
        this.inputElement.value = '';
        document.execCommand('insertText', false, pastedInput);
    }
}
