<ion-card class="event-form">
  <form [formGroup]="resetForm">
          <ion-item>
            <ion-label position="floating">New Password:</ion-label>
            <ion-input appRestrictSpace formControlName="newPassword" placeholder="New Password" type="password" required></ion-input>
            <ion-note slot="error" *ngIf="isTouched('newPassword') && (hasError('newPassword','required') || hasError('newPassword','minlength'))">
              <ng-container *ngIf="hasError('newPassword','required')">
                This field is required
              </ng-container>
              <ng-container *ngIf="hasError('newPassword','minlength')">
                Password must be at least 8 characters
              </ng-container>
            </ion-note>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Confirm Password:</ion-label>
            <ion-input appRestrictSpace formControlName="confirmPassword" placeholder="Confirm Password" type="password" required></ion-input>
            <ion-note slot="error" *ngIf="isTouched('confirmPassword') && (hasError('confirmPassword','required') || hasError('confirmPassword','notMatched'))">
              <ng-container *ngIf="hasError('confirmPassword','required')">
                This field is required
              </ng-container>
              <ng-container *ngIf="!hasError('confirmPassword','required') && hasError('confirmPassword','notMatched')">
                Passwords do not match
              </ng-container>
            </ion-note>
          </ion-item>
          <app-button buttonText="Cancel" color="danger" expand="block" size="large" (buttonClick)="cancel()"></app-button>
          <app-button buttonText="Reset Password" color="success" expand="block" size="large" (buttonClick)="submit()"></app-button>
  </form>
</ion-card>