<ion-card class="slider" *ngIf="imageList?.length||imageToUploadList?.length">                  
    <swiper [config]="slideOpts">
        <ng-container *ngIf="imageList?.length">
            <ng-template swiperSlide *ngFor="let image of imageList; index as i">
              <ng-container *ngTemplateOutlet="imageTemplate; context:{$implicit: {src:image,type:'uploaded', index:i}}"> </ng-container>
            </ng-template>
        </ng-container>
      <ng-container *ngIf="imageToUploadList?.length">
          <ng-template swiperSlide *ngFor="let image of imageToUploadList; index as i">
            <ng-container *ngTemplateOutlet="imageTemplate; context:{$implicit: {src:image.image,type:'new', index:i}}"> </ng-container>
          </ng-template>
      </ng-container>
    </swiper>
    <ion-label class="slider-nav">
      <ion-icon name="arrow-back-outline" [class]="'swiper-button-prev'+id"></ion-icon>
      <ion-icon name="arrow-forward-outline" [class]="'swiper-button-next'+id"></ion-icon>
    </ion-label>
</ion-card>  

<ng-template #imageTemplate let-image>
    <ion-img [src]="image.src" (ionImgDidLoad)="showLoader=false" [ngClass]="{'is-loaded': !showLoader}"></ion-img>
    <app-skeleton type="fill"></app-skeleton>
    <ion-icon *ngIf="!showLoader && showCross" name="close-circle-outline" (click)="deleteImageClicked.next({index: image.index, type:image.type})"></ion-icon>
</ng-template>
