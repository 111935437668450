import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { RealmService } from './realm.service';

@Injectable({
    providedIn: 'root',
})
export class StaffService {
    public staffList: BehaviorSubject<User[]> = new BehaviorSubject([]);

    constructor(private realmService: RealmService) {}

    public async getStaff() {
        const result = await this.realmService.realmApp?.currentUser?.functions.getSystemUsers();
        this.staffList.next(result);
    }

    public async registerUser(email: string, password: string): Promise<any> {
        return this.realmService.registerUser(email, password);
    }

    public async confirmUser(token: string, tokenId: string): Promise<any> {
        return this.realmService.confirmUser(token, tokenId);
    }

    public async createUser(userData: User): Promise<any> {
        return this.realmService.realmApp?.currentUser?.functions.createUser(userData);
    }

    public async getUserDetails(_id: string): Promise<any> {
        return this.realmService.realmApp?.currentUser?.functions.getUserDetailByID(_id);
    }

    public async getUserDetailByEmail(email: string): Promise<any> {
      return await this.realmService.realmApp.currentUser?.functions.getUserDetailByEmail(email);
    }

    public async updateUser(userData: User): Promise<any> {
        return this.realmService.realmApp?.currentUser?.functions.updateUser(userData);
    }

    public async sendResetPasswordEmail(email: string): Promise<any> {
        return this.realmService.sendResetPasswordEmail(email);
    }

    public async resetPassword(password: string, token: string, tokenId: string): Promise<any> {
      return this.realmService.resetPassword(password, token, tokenId);
  }
}
