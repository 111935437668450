<ng-container *ngIf="inventoryList && !loadingInventoryData">

    <ion-card class="add-charges popup-block__wrap">
        <ion-label class="add-charges__title h2" color="black">Select Item to Charge:</ion-label>

        <ion-item>
            <ion-label position="floating" for="payment"> Item </ion-label>
            <ion-select class="form-control" [interfaceOptions]="{cssClass: 'is-large'}" id="payment" [(ngModel)]="selectedItem" (ionChange)="chargeAmount=null;chargeQuantity=null">
                <ng-container *ngFor="let item of inventoryList">
                    <ion-select-option [value]="item"> {{item?.name}} - {{ item?.customPricing ? 'Custom' : (item.price | currency)}} </ion-select-option>
                </ng-container>
                <ion-select-option [value]="{name:'others'}"> Others </ion-select-option>
            </ion-select>
        </ion-item>
        <ng-container *ngIf="selectedItem">
        <ng-container *ngIf="selectedItem?._id; else customInput">
            <ng-container *ngIf="selectedItem?.customPricing; else quantityInput">
                <ng-content *ngTemplateOutlet="amountField"></ng-content>
            </ng-container>
            <ng-template #quantityInput>
                <ion-item>
                    <ion-label position="floating" position="floating"> Quantity To Add: </ion-label>
                    <ion-input name="quantity" type="text" numberOnly [(ngModel)]="chargeQuantity"
                    [ngModelOptions]="{standalone: true}" required></ion-input>
                    <ion-note slot="error"> This field is required </ion-note>
                </ion-item>
            </ng-template>
        </ng-container>
        <ng-template #customInput>
            <ion-item>
                <ion-label position="floating" position="floating"> Charge Reason: </ion-label>
                <ion-input name="refund" type="text" placeholder="Charge Reason" [(ngModel)]="selectedItem.description"
                [ngModelOptions]="{standalone: true}" required></ion-input>
                <ion-note slot="error"> This field is required </ion-note>
            </ion-item>
            <ng-content *ngTemplateOutlet="amountField"></ng-content>
        </ng-template>
        </ng-container>

        <ion-card-content class="popup-block__footer" >
            <app-button buttonText="Cancel" color="primary" (buttonClick)="buttonClicked(false)"></app-button>
            <app-button [disabled]="isValidCharge" buttonText="Add New Charge" color="success" (buttonClick)="buttonClicked(true)"></app-button>
        </ion-card-content>
    </ion-card>

</ng-container>

<ng-template #amountField>
    <ion-item>
        <ion-label position="floating" position="floating"> Amount to Charge: </ion-label>
        <ion-input name="amount" type="text" numberOnly [hasDecimal]="true" [limitAfterDecimal]="2" [(ngModel)]="chargeAmount"
        [ngModelOptions]="{standalone: true}" required></ion-input>
        <ion-note slot="error"> This field is required </ion-note>
    </ion-item>
</ng-template>
