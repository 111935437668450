import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { RealmService } from '../services/realm.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  userData: User;

  constructor(private router: Router, private authService: AuthService, private realmService: RealmService) {
    this.userData = this.authService.getUserData();
  }

  canActivate(route: ActivatedRouteSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userId = this.realmService.realmApp.users[0]?.id;
    if (route?.data?.action === 'signup') {
      this.router.navigate(['signup']);
      return true;
    }

    if (!userId) {
      this.router.navigate(['/']);
    }
    const checkToken = localStorage.getItem(`realm-web:app(${environment.constants.APP_ID}):user(` + userId + '):accessToken');

    if (checkToken) {
      if (this.userData?.roles.includes('member') && this.userData?.memberId && this.userData?.ownerNumber && route.data?.page === 'dashboard') {
        this.router.navigate(['member-dashboard']);
      } else if (this.userData && !this.userData?.memberId && !this.userData?.ownerNumber && route.data?.page === 'member-dashboard') {
        this.router.navigate(['dashboard']);
      }
      return true;
    } else {
      this.router.navigate(['/']);
    }
    return false;
  }
}
