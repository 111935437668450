export enum RESERVATIONTYPE {
    NONMEMBER = 'Non-Member',
    RPI = 'RPI',
    GUEST = 'Guest',
    MEMBER = 'Member',
    NINEDAYBUY = '9Day'
}

export enum STORAGEMOVE {
    STORAGEMOVE = 'Storage Move'
}

export enum RESERVATION_STATUS { 
    PENDING   = 'Pending',
    IN_HOUSE  = 'inHouse',
    CANCELLED = 'Cancelled',
    COMPLETED = 'Completed',
    INITIATED = 'initiated'
}