import { Injectable } from '@angular/core';
import { RealmService } from './realm.service';
import * as Realm from 'realm-web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StaffService } from './staff.service';
import { NavController } from '@ionic/angular';
import { BnNgIdleService } from 'bn-ng-idle';
import { User } from '../models/user';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public userData$: BehaviorSubject<User> = new BehaviorSubject(null);
  private sessionSubscription: Subscription;

  constructor(
    private navCtrl: NavController,
    private realm: RealmService,
    private staffService: StaffService,
    private bnIdle: BnNgIdleService,
  ) {
  }

  public getUserData() {
    const localStorageData = localStorage.getItem('userData') || null;
    return localStorageData ? JSON.parse(localStorageData) : null;
  }

  public async login(username: string, password: string) {
    const app = await this.realm.realmApp;
    const credentials = Realm.Credentials.emailPassword(username, password);
    try {
      // Authenticate the user
      const user: any = await app.logIn(credentials);
      let userData = user?.customData || null;
      if ( !user.customData || !user?.customData?.userId ) {
        userData = await this.staffService.getUserDetailByEmail(user?.profile?.data?.email || user?.profile?.email);
        // eslint-disable-next-line no-underscore-dangle
        userData._id = userData?._id?.toString();
        userData.userId = user.id;
        await this.staffService.updateUser(userData);
        localStorage.setItem('userData', JSON.stringify(userData));
        this.userData$.next(userData);
      } else {
        localStorage.setItem('userData', JSON.stringify(user.customData));
        this.userData$.next(user.customData);
      }
      this.authStatus.next(true);

      // eslint-disable-next-line no-underscore-dangle
      Sentry.setUser({ email: username, id: userData.userId, username: userData?.first + ' ' + userData?.last });

      this.startIdeTimer();
      if (userData && userData?.roles?.includes('member') && userData?.memberId && userData?.ownerNumber) {
        this.navCtrl.navigateForward('/member-dashboard', { replaceUrl: true, animated: false  });
      } else {
        this.navCtrl.navigateForward('/dashboard', { replaceUrl: true, animated: false  });
      }

    } catch (err) {
      console.log( err );
      throw err;
    }
  }

  public async logout() {
    const app = this.realm.realmApp;
    localStorage.clear();
    try {
      if (app?.currentUser) {
        await app.currentUser.logOut();
      }
      this.authStatus.next(false);
      if (this.sessionSubscription) {
        this.sessionSubscription.unsubscribe();
      }
      await this.navCtrl.navigateForward('/', { replaceUrl: true, animated: false });
      location.reload();
    } catch (err) {
      this.navCtrl.navigateForward('/login', { replaceUrl: true, animated: false });
      console.error('Failed to log in', err);
    }
  }

  private startIdeTimer() {
    this.sessionSubscription = this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.stopIdeTimer();
        this.logout();
      }
    });
  }

  private stopIdeTimer() {
    this.bnIdle.stopTimer();
  }
}
