import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appRestrictSpace]'
})
export class RestrictSpaceDirective {

  @Input() restrictValue: string;
  @Input() noSpace = false;
  @Input() noText = false;
  inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(e){
    const value = e.target.value;
    if (
      this.noText ||
      (e.keyCode === 32 && value[value.length - 1] === ' ' && !this.restrictValue) ||
      (this.restrictValue && this.restrictValue.split('').includes(e.key) ) ||
      (this.noSpace && e.keyCode === 32) ||
      ( e.keyCode === 32 && !value.trim() )
      ){
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {

      event.preventDefault();
      const value = event.clipboardData.getData('text/plain');
      if ( this.noText ){
        return;
      } else if( this.restrictValue ){
        document.execCommand('insertText', false, value.replace( /[\W_]+/g, '' ) );
      } else {
        document.execCommand('insertText', false, value );
      }
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
      event.preventDefault();
      const value = event.dataTransfer.getData('text/plain').replace( /[\W_]+/g, '' );
      if ( this.noText ){
        return;
      }else if( this.restrictValue ){
        this.inputElement.focus();
        document.execCommand('insertText', false, value.replace( /[\W_]+/g, '' ) );
      } else {
        this.inputElement.focus();
        document.execCommand('insertText', false, value);
      }
    }
}
