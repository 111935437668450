import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { AuthService } from './auth.service';
import { ErrorType } from '../enum/errorType.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private user: User;

  constructor( protected http: HttpClient, private toastCtrl: ToastController, private authService: AuthService) {
    this.authService.userData$.subscribe((res) => {
      if( res ){
        this.user = res;
      } else {
        const localStorageData = localStorage.getItem('userData') || null;
        const storedUserData = localStorageData ? JSON.parse(localStorageData) : null;
        if( storedUserData && storedUserData.userId && storedUserData.status ){
          this.user = storedUserData;
        }
      }
  });
  }

  public getUploadUrl(imageData: {
    fileName: string;
    fileType: string;
    uploadType: string;
    id: string; }[]) {
      // eslint-disable-next-line max-len, quote-props, @typescript-eslint/naming-convention
      imageData.forEach( ( img, index ) => {
        imageData[index].id = imageData[index].id.replace(/\s+|#/g,'');
      });
      const headers = new HttpHeaders({'Authorization':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJIYXJ0IFJhbmNoIiwibmFtZSI6IkNhbGxlZCBmcm9tIEZyb250IEVuZCBvZiBIYXJ0IFJhbmNoIiwiaWF0IjoxNjY1MDQxNDMwMzQyfQ.n0aqLja3052V6xzFDTXX-o6iLLui-y14ZhrB3UIjZAI'});
      return this.http.post<any>(`${environment.imageUploadUrl}/getAwsSignedUrl`, {imageData}, {headers} );
  }

  public uploadImages(url: string, file: File){
    return this.http.put<any>(url, file);
  }

  public getTime(timestamp: string): string {
    return moment(moment.utc(timestamp).local()).fromNow();
  }

  async showToast(message: string) {
    const toast = await this.toastCtrl.create({
        message,
        duration: 4000,
        position: 'top',
    });
    toast.present();
  }

  public getDateDiff(endDate: any, startDate: any): number {
    const sD= new Date(startDate).setHours(0,0,0,0);
    const nights = moment(endDate).diff(sD, 'days');
    return nights?nights:1;
  }

  public getDiff(endDate: any, startDate: any): number {
    return moment(endDate).diff(startDate, 'days');
  }

  public updateField(form: FormGroup,field: string){
    form.controls[field].setValue(form.controls[field].value?.trim());
  }

  public refinePayload(payload: any){
    const keys = Object.keys(payload);
    const refinedPayload = {};
    keys.forEach(key=>{
      if( payload[key] !== null && payload[key] !== undefined && (
        typeof payload[key]==='string' ||
        typeof payload[key]==='boolean' ||
        typeof payload[key]==='number' ||
        ( Array.isArray(payload[key]) && payload[key].length) ||
        ( !Array.isArray(payload[key]) && typeof payload[key]==='object')
      )){
        refinedPayload[key]=payload[key];
      }
    });
    return refinedPayload;
  }

  public updateTimeOffset(date: string| Date): string{
    return date?new Date( new Date(date).getTime()-new Date().getTimezoneOffset()*60*1000 )?.toISOString():'';
  }

  public isFDRole(){
    return this.user?.roles?.some(role=> ( role?.toLowerCase().includes('reservation') || role?.toLowerCase().includes('admin') || role?.toLowerCase().includes('sales') ) );
  }

  public dateToStringLocal(timestamp: any){
    if(timestamp){
      return moment(timestamp).startOf('day').format('MMM DD, YYYY');
    }else{
      return timestamp;
    }
  }

  public getErrorMessage( limitDays: number, type: ErrorType = ErrorType.EXCEED, confirmation: boolean = true, limitDays2?: number ){
    if(!(limitDays >= 0)){
      limitDays = 1;
    }
    if(!(limitDays2 >= 0)){
      limitDays2 = 1;
    }
    let error = "You "+ ( this.isFDRole() ? 'are about to' : 'can not' )+" create a reservation ";

    switch( type ){
      case ErrorType.EXCEED: {
        error +=  "surpassing "+ limitDays + (limitDays > 1? " days": ' day') + " from current day.";
        break;
      }
      case ErrorType.LIMIT: {
        error +=  "exceeding "+ limitDays + (limitDays > 1? " nights": ' night') + " in 30 days period.";
        break;
      }
      case ErrorType.BOTH: {
        error +=  "exceeding "+ limitDays + (limitDays > 1? " nights": ' night') + " in 30 days period ";
        error += "and surpassing "+ limitDays2 + (limitDays2 > 1? " days": ' day') + " from current day.";
        break;
      }
      default: {
        error = ''
      }
    }

    if( confirmation && this.isFDRole() ){
      error += " Are you sure you want to proceed?";
    }

    return error;
  }

  public formatUtcToLocalString(timestamp: any){
    if(timestamp){
      return moment.utc(timestamp).startOf('day').format('MMM DD, YYYY');
    }else{
      return timestamp;
    }
  }
}
