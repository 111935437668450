import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Inventory } from 'src/app/core/models/inventory';
import { InventoryManagerService } from 'src/app/core/services/inventory-manager.service';
import { MembersService } from 'src/app/core/services/members.service';
import { ReservationsService } from 'src/app/core/services/reservations.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-add-charges',
  templateUrl: './add-charges.component.html',
  styleUrls: ['./add-charges.component.scss'],
})
export class AddChargesComponent implements OnInit {

  @Input() reservationNumber: number;
  @Input() memberNumber?: string;
  @Input() isMemberFolio: boolean = false;
  @Input() updateByApi: boolean = true;
  @Input() showLoader: boolean = true;
  @Input() inventoryItems: Inventory[] = null;
  @Output() onClose: EventEmitter< { inventory: Inventory, chargeAmount: number; chargeQuantity: number}[] > = new EventEmitter();

  public loadingInventoryData: boolean = false;
  public chargeAmount: string;
  public chargeQuantity: number;
  public inventoryList: Inventory[];
  public selectedItem: Inventory;

  constructor( private reservationService: ReservationsService,
    private loadingController: LoadingController,
    private utilService: UtilService,
    private inventoryService: InventoryManagerService ) { }

    get isValidCharge(): boolean {
      if( this.selectedItem?._id && this.selectedItem?.customPricing ){
        return !this.chargeAmount;
      } else if( this.selectedItem?._id && !this.selectedItem?.customPricing ) {
        return !this.chargeQuantity;
      } else if( !this.selectedItem?._id ){
        return !this.chargeAmount || !this.selectedItem?.description;
      }
      return false;
    }

  ngOnInit() {
    if( this.inventoryItems ){
      this.inventoryList = this.inventoryItems;
    } else {
      this.getInventroyItems();
    }
  }

  buttonClicked( event: boolean ){
    if( event ){

      if( !this.selectedItem ){
        this.utilService.showToast('Please select a valid item to charge');
        this.onClose.next( null );
        return;
      }

      if( this.updateByApi ){
        this.addCharge();
      } else {
        if( this.selectedItem?._id && !this.selectedItem?.customPricing ){
          this.chargeAmount = ( this.selectedItem.price * this.chargeQuantity ).toFixed(2);
        }

        const selectedItems = [{
          inventory: {...this.selectedItem},
          chargeAmount: Number(this.chargeAmount),
          chargeQuantity: this.chargeQuantity || 0
        }];

        this.onClose.next( selectedItems );
        this.resetValue();
      }

    }else {
      this.onClose.next( null );
      this.resetValue();
    }
  }

  private async addCharge(){

    if( this.isMemberFolio && !this.memberNumber ){
      this.utilService.showToast('Invalid Member');
      return;
    }

    if( !this.isMemberFolio && !this.reservationNumber ){
      this.utilService.showToast('Invalid Reservation');
      return;
    }

    const addincCharge = await this.loadingController.create({
      message: 'Adding Charge...',
    });
    await addincCharge.present();

    const payload:any = {
      selectedItems :[]
    };

    if( this.isMemberFolio ){
      payload.ownerNumber = this.memberNumber;
      payload.isMemberFolio = true;
    } else {
      payload.reservationNumber = this.reservationNumber;
    }

    if( this.selectedItem?._id && !this.selectedItem?.customPricing ){
      this.chargeAmount = ( this.selectedItem.price * this.chargeQuantity ).toFixed(2);
    }

    payload.selectedItems.push({
      inventory: this.selectedItem,
      chargeAmount: Number(this.chargeAmount),
      chargeQuantity: this.chargeQuantity || 0
    });

    this.reservationService.addCharge(  payload  ).then( (res) => {
      this.onClose.next( payload.selectedItems || [] );
      this.resetValue();
    })
    .catch( ( err ) => {
      try{
        const errMessage = err?.error ? JSON.parse( err?.error )?.message : 'Something went wrong';
        this.utilService.showToast( errMessage );
      }catch( error ){
        this.utilService.showToast( err?.error ? err?.error : "Something went wrong" );
      }
    })
    .finally(()=>{
      addincCharge.dismiss();
    });
  }

  private async getInventroyItems(){
    let fetchingItems = null;
    if( this.showLoader ){
      fetchingItems = await this.loadingController.create({
        message: 'Fetching Items...',
      });
      await fetchingItems.present();
    }

    this.loadingInventoryData = true;
    this.inventoryService.searchInventory('', true)
    .then((inventory) => {
      this.inventoryList = inventory || [];
    })
    .catch( ( err ) => {
      try{
        const errMessage = err?.error ? JSON.parse( err?.error )?.message : 'Something went wrong';
        this.utilService.showToast( errMessage );
      }catch( error ){
        this.utilService.showToast( err?.error ? err?.error : "Something went wrong" );
      }
    })
    .finally(()=>{
      this.loadingInventoryData = false;
      if( fetchingItems ){
        fetchingItems.dismiss();
      }
    });
  }

  public resetValue(){
    if( this.selectedItem && !this.selectedItem._id ){
      this.selectedItem.description = null;
    }
    this.selectedItem = null;
    this.chargeAmount = null;
  }

}
