import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './core/services/auth.service';
import { RealmService } from './core/services/realm.service';
import { StaffService } from './core/services/staff.service';
import { NavController } from '@ionic/angular';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  public authorized = false;
  public allow = false;
  public isLoading = true;
  public route;

  constructor(
    private authService: AuthService,
    private realmService: RealmService,
    private staffService: StaffService,
    private router: Router,
    private navCtrl: NavController ) {
    this.authService.authStatus.subscribe((value) => {
      this.authorized = value;
    });
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.route = event.url;
      this.checkAuth();
    });
  }

  async ngOnInit() {
    const localStorageData = localStorage.getItem('userData') || null;
    const storedUserData = localStorageData ? JSON.parse(localStorageData) : null;
    if (storedUserData && storedUserData.email) {
      const userData = await this.staffService.getUserDetailByEmail(storedUserData.email);
      if (userData) {
        // eslint-disable-next-line no-underscore-dangle
        userData._id = userData?._id.toString();
        localStorage.setItem('userData', JSON.stringify(userData));
        this.authService.userData$.next(userData);
      } else {
        localStorage.clear();
        this.navCtrl.navigateForward('/login', { replaceUrl: true, animated: false });
      }
    }
  }

  private checkAuth() {
    try {
      const userId = this.realmService.realmApp.users[0].id;
      const checkToken = localStorage.getItem(`realm-web:app(${environment.constants.APP_ID}):user(` + userId + '):accessToken');
      if (checkToken) {
        this.authorized = true;
        //TODO: This should not be triggered when members login
        this.loadSystemUsers();
      } else {
        this.authorized = false;
      }
      this.isLoading = false;
      if (this.route?.indexOf('reset-password') !== -1 || this.route?.indexOf('confirm-email') !== -1) {
        this.allow = true;
      }
    } catch (e) {
      this.isLoading = false;
      if (this.route?.indexOf('reset-password') !== -1 || this.route?.indexOf('confirm-email') !== -1) {
        this.allow = true;
      }
      this.authorized = false;
    }
  }

  private loadSystemUsers() {
    this.staffService.getStaff();
  }
}
