import { MbscModule } from '@mobiscroll/angular';
import { FormsModule } from '@angular/forms';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {Router, RouteReuseStrategy} from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { CommonComponentsModule } from './core/components/common-components/common-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import * as Sentry from '@sentry/angular-ivy';
import { BnNgIdleService } from 'bn-ng-idle';

// const config: SocketIoConfig = { url: 'http://localhost:4000', options: {} };

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    // eslint-disable-next-line max-len
    imports: [
        MbscModule,
        FormsModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FontAwesomeModule,
        CommonComponentsModule,
        //SocketIoModule.forRoot(config),
        BrowserAnimationsModule,
        HttpClientModule
    ],
    providers: [
      BnNgIdleService,
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: true,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {
        },
        deps: [Sentry.TraceService],
        multi: true,
      },
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private library: FaIconLibrary) {
        // @ts-ignore
      library.addIconPacks(fas, fab, far);
    }
}
