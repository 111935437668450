
<ion-card class="confirmation"  *ngIf="success !== undefined">
  <ion-card-content>
      <ion-icon *ngIf="success" color="success" name="checkmark-circle-outline"></ion-icon>
      <ion-text *ngIf="success">Email Confirmed Successfully</ion-text>

      <ion-icon *ngIf="!success" color="warning" name="warning-outline"></ion-icon>
      <ion-text *ngIf="!success">Verification Link Expired or Invalid!</ion-text>
      
      <app-button buttonText="Login" color="success" (buttonClick)="navigateToLogin()" ></app-button>   
  </ion-card-content>
</ion-card>