<ion-row class="row gutter-lg" *ngIf="ticketList.length; else noData">
    <ion-col size="12" size-md="6" size-lg="12" size-xl="6" *ngFor="let ticket of ticketList;index as i">
        <ion-card class="card-info" [ngClass]="{
            'card-info--yellow': checkClassColor(i,'maintenance'), 
            'card-info--green': checkClassColor(i,'grounds'),
            'card-info--purple': checkClassColor(i,'housekeeping')}">
            <ion-card-header>
                <ion-card-title>
                    #{{ticket?.ticketNumber}}
                    <span class="ion-float-right pointer">
                        <ion-icon (click)="openTicket(ticket)" name="open-outline"></ion-icon>
                    </span>
                </ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-text class="card-info__text">{{ ticket.request }}</ion-text>
                <ion-footer>
                    <ion-row>
                        <ion-col class="ion-text-left">
                            Created by: <ion-text class="card-info__block">{{ ticket.createdBy }}</ion-text>
                        </ion-col>
                        <ion-col class="ion-text-right">
                            <ion-text class="card-info__assigne ion-text-left">
                                Assigned to: <ion-text>{{ ticket.taskDetails?.assignedTo }}</ion-text>
                            </ion-text>
                        </ion-col>
                    </ion-row>
                </ion-footer>
            </ion-card-content>
        </ion-card>
    </ion-col>
</ion-row>

<ng-template #noData>
    <ion-row class="ion-justify-content-center">
        <ion-col size="12" class="ion-text-center ion-padding-top">
            No Tickets Found!
      </ion-col>
    </ion-row>
</ng-template>