<ion-content class="sidebar">
    <ion-list id="inbox-list">
        <ion-img class="sidebar__logo" src="assets/images/logo.png"></ion-img>
        <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i= index">
            <ion-item id="{{ p.id }}" routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" roleAccess action="{{p.action}}" routerLinkActive="selected">
                <ion-label>
                    <ion-img [src]="'assets/images/'+p.icon+'.svg'"></ion-img>
                    <ion-img [src]="'assets/images/'+p.icon+'-red.svg'"></ion-img>
                </ion-label>
                <ion-text>{{ p.title }}</ion-text>
            </ion-item>
        </ion-menu-toggle>
    </ion-list>
</ion-content>
