import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-media-upload',
    templateUrl: './media-upload.component.html',
    styleUrls: ['./media-upload.component.scss'],
})
export class MediaUploadComponent implements OnInit {

    @Input() multiple = false;
    @Input() displaySelectedImages = false;
    @Output() imageUpload: EventEmitter<{ image: string; file: File }[]> = new EventEmitter();

    public acceptType = 'image/jpeg, image/png';
    public loading = false;
    public loadMoreAvailable = true;
    public imageBinary: string[] = [];
    public error: string;
    public inputFileType: ('image' | 'video' | 'svg')[] = [];
    public processedFile: File[] = [];
    public totalUploadedImages = 0;

    ngOnInit(): void {}

    public readFile(event){
        if(event.target?.files.length){
            this.onSelectFile(event.target.files);
        }
    }

    public onSelectFile(files: File[]): void {
        this.totalUploadedImages = files.length;
        if (this.multiple) {
            Array.from(files).forEach((file) => {
                const fileType: 'image' | 'video' | 'svg' =
                file.type.indexOf('video') >= 0 ? 'video' : file.type.indexOf('svg') >= 0 ? 'svg' : 'image';
                this.processFile(file, fileType);
            });
        } else {
            const fileType: 'image' | 'video' = files[0].type.indexOf('video') >= 0 ? 'video' : 'image';
            this.processFile(files[0], fileType);
        }
    }

    public processFile(file: File, type: 'image' | 'video' | 'svg') {
        this.error = '';
        const reader = new FileReader();
        if (type === 'image' || type === 'svg') {
            reader.onload = (event: any) => {
                this.imageBinary.push(event.target.result);
                this.inputFileType.push(type);
                this.processedFile.push(file);
                this.totalUploadedImages--;
                this.updateImage('image');
            };
            reader.onerror = (error) => {
                console.log('Error: ', error);
            };
            reader.readAsDataURL(file);
        } else {
            reader.onload = this.handleReaderLoaded.bind(this, file);
            reader.readAsBinaryString(file);
        }
    }

    public deleteImage(index: number) {
        this.imageBinary.splice(index, 1);
        this.processedFile.splice(index, 1);
        this.inputFileType.splice(index, 1);
    }

    public updateImage(type: string): void {
        if (type === 'image') {
            if (this.imageBinary && this.imageBinary.length > 0) {
                const result = this.imageBinary.map((image, index) => ({ image, file: this.processedFile[index] || null }));
                this.imageUpload.emit(result);
                this.resetData();
            }
        }
    }

    private resetData(){
        this.imageBinary = [];
        this.processedFile = [];
        this.totalUploadedImages = 0;
    }

    private handleReaderLoaded(file: File, readerEvt: any): void {
        const binaryString = readerEvt.target.result;
        const base64textString = btoa(binaryString);
        const selectedImageBase64 = `${`data:${file.type};base64,`}${base64textString}`;
        this.imageBinary.push(selectedImageBase64);
        this.inputFileType.push('video');
        this.processedFile.push(file);
        this.totalUploadedImages--;
    }

}
