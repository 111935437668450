<ion-card class="reservation-card card-format">
    <ion-header class="flex ion-align-items-center ion-justify-content-between">
        <ion-text>#{{ reservation?.reservationNumber }}</ion-text>
        <ion-img *ngIf="showCheckin" (click)="dispatchCheckinClick(reservation?.reservationNumber)" src="assets/images/check-green.svg"></ion-img>
        <ion-img (click)="dispatchReservationClick(reservation?.reservationNumber)" src="assets/images/external-link.svg"></ion-img>
    </ion-header>

    <ion-card-title class="reservation-card__number" color="dark">{{ reservation?.guests?.length?reservation?.guests[0]?.last:''}}, {{reservation?.guests?.length?reservation?.guests[0]?.first:'' }}</ion-card-title>

    <ion-card-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-label>Stay:</ion-label>
            <ion-text> {{ utils.dateToStringLocal((reservation?.checkin)) }} - {{ utils.dateToStringLocal((reservation?.checkout)) }}</ion-text>
          </ion-col>
        </ion-row>
        <ng-container *ngIf="reservation?.housekeepingStatus && showCheckin">
          <ion-row>
            <ion-col>
              <ion-label>Housekeeping Status :</ion-label>
              <ion-text> {{ reservation?.housekeepingStatus }}</ion-text>
            </ion-col>
          </ion-row>
        </ng-container>
        <ion-row>
          <ion-col>
            <ion-label>{{ reservation?.isCabin ? 'Cabin ': 'Site ' }}:</ion-label>
            <ion-text> {{ reservation?.siteGroupName ? '('+reservation?.siteGroupName+')/ ' : ''}}{{ reservation?.isCabin ? 'Cabin ': 'Site ' }}#{{ reservation?.siteNo}}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-label>Reservation Type:</ion-label>
            <ion-text> {{ reservation?.reservationType || 'Member'}}</ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>

    <ion-footer>
<!--        <ion-row class="ion-justify-content-between">-->
<!--            <ion-col size="auto" class="ion-text-left" *ngIf="reservation?.guests?.length?reservation?.guests[0].phone[0]:null">-->
<!--                <ion-label>Phone:</ion-label>-->
<!--                <ion-text> {{ reservation?.guests[0].phone[0] }}</ion-text>-->
<!--            </ion-col>-->
<!--            <ion-col size="auto" class="ion-text-right" *ngIf="reservation?.guests?.length?reservation?.guests[0].email:null">-->
<!--                <ion-label>Email:</ion-label>-->
<!--                <ion-text> {{ reservation?.guests[0].email }}</ion-text>-->
<!--            </ion-col>-->
<!--        </ion-row>-->
    </ion-footer>
</ion-card>
