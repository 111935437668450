import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

  @Input() fill = 'solid';
  @Input() color = 'primary';
  @Input() buttonText: string;
  @Input() disabled = false;
  @Input() iconSlot: 'end'|'start';
  @Input() iconName = 'add-outline';
  @Input() iconSrc: string;
  @Input() expand: string;
  @Input() size: string;
  @Input() stopPropagation = false;
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter();

  public showIcon: boolean;
  
  ngOnInit(){
    this.showIcon=this.iconName || this.iconSrc ? true : false;
  }

  onButtonClicked( event: any ){
    if(this.stopPropagation ){
      event.stopPropagation();
    }
    this.buttonClick.next(true);
  }
}
