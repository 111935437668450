import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import moment from 'moment';
@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {

    @Input() displayType: 'inline';
    @Input() placeHolder = 'Date Range';
    @Input() isInvalid = true;
    @Input() errorMessage = '';
    @Input() showHeadbar: string;
    @Input() minValue: any = '';
    @Input() maxValue: any = '';
    @Input() displayDateFormat = 'MM/DD/YYYY';
    @Input() disabled = false;
    @Input() showReset = true;
    @Input() showRangeLabels = true;
    @Input() maxRange: number;
    @Input() minRange: number;
    @Input() colors = [];
    @Input() pickerType: 'date' | 'range' = 'range';
    @Input() selectedDates: any[] = null;
    @Input() inValidRange: string[] = [];
    @Output() selectedRange: EventEmitter<any> = new EventEmitter();

    constructor( ) {}

    public get maxDateValue(){
        if( this.pickerType==='range' && this.maxRange && this.selectedDates?.length && this.selectedDates[0]){
            const selectedStartDate = new Date(this.selectedDates[0]);
            const maxDate = new Date(selectedStartDate.setDate(selectedStartDate.getDate()+this.maxRange)).getTime();
            const maxdateVal2 = maxDate?maxDate:0;
            const maxdateVal1 = this.maxValue?new Date(this.maxValue).getTime():0;
            if((maxdateVal2-maxdateVal1)>0){
                return maxDate;
            }
        }
        return this.maxValue;
    }

    ngOnInit(){ }

    ngOnChanges(){
        if( this.selectedDates?.length){
            this.selectedDates?.forEach((date,i)=>{
                    this.selectedDates[i] = date?new Date(date):null;
            });
        }
    }

    public filter() {
        if(this.pickerType==='range'){
            const selectedDateRange = this.selectedDates?.map((date,i)=>{
                if(date){
                    return this.selectedDates[i] = moment(date).startOf('day').format('MMM DD, YYYY');
                }else{
                    return date;
                }
            });
            this.selectedRange.next(selectedDateRange?.length?selectedDateRange:null);
        }else{
            this.selectedRange.next(this.selectedDates?moment(this.selectedDates).startOf('day').format('MMM DD, YYYY'):null);
        }
    }

}
