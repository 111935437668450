<ion-card class='detail-form'>
  <ng-container *ngIf='memberForm'>
    <form [formGroup]='memberForm'>
      <ion-row class='gutter-lg'>
        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating' position='floating'>Member Number:</ion-label>
            <ion-input type='text' appRestrictSpace [readonly]='memberForm?.controls?.ownerNumber?.disabled'
                       [disabled]='memberForm?.controls?.ownerNumber?.disabled' [noSpace]='true'
                       restrictValue=' #' formControlName='ownerNumber'></ion-input>
            <ion-note *ngIf="isTouched('ownerNumber')" slot='error'>You must enter a valid member number.
            </ion-note>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-label position='floating' position='floating'>Associate Type:</ion-label>
            <ion-select formControlName='associateType'>
              <ion-select-option value="Co-Member">
                Co-Member
              </ion-select-option>
              <ion-select-option value="Associate">
                Associate
              </ion-select-option>
            </ion-select>
          </ion-item>
          </ion-col>
        <ion-col size='12'>
            <ion-item class="input-checkbox detail-form__check" lines="none">
                <ion-checkbox formControlName="membershipStatus"></ion-checkbox>
                <ion-label>Membership Active</ion-label>
            </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class='gutter-lg'>
        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating' position='floating'>First Name:</ion-label>
            <ion-input type='text' appRestrictSpace
                       formControlName='firstName'></ion-input>
            <ion-note *ngIf="isTouched('firstName')" slot='error'>You must enter a valid first name.</ion-note>
          </ion-item>
        </ion-col>
        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating'>Last Name:</ion-label>
            <ion-input appRestrictSpace type='text'
                       formControlName='lastName'></ion-input>
            <ion-note *ngIf="isTouched('lastName')" slot='error'>You must enter a valid last name.</ion-note>
          </ion-item>
        </ion-col>

        <ion-col size='12'>
          <ion-item>
            <ion-label position='floating'>Address:</ion-label>
            <ion-input appRestrictSpace type='text'
                       formControlName='address'></ion-input>
            <ion-note *ngIf="isTouched('address')" slot='error'>You must enter a valid address.</ion-note>
          </ion-item>
        </ion-col>
        <ion-col size='12'>
          <ion-item>
            <ion-label position='floating'>Address2:</ion-label>
            <ion-input appRestrictSpace type='text'
                       formControlName='address2'></ion-input>
            <ion-note *ngIf="isTouched('address')" slot='error'>You must enter a valid address.</ion-note>
          </ion-item>
        </ion-col>

        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating'>City:</ion-label>
            <ion-input appRestrictSpace type='text' formControlName='city'></ion-input>
            <ion-note *ngIf="isTouched('city')" slot='error'>You must enter a valid city.</ion-note>
          </ion-item>
        </ion-col>
        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating'>State:</ion-label>
            <ion-input appRestrictSpace type='text' formControlName='state'></ion-input>
            <ion-note *ngIf="isTouched('state')" slot='error'>You must enter a state.</ion-note>
          </ion-item>
        </ion-col>
        </ion-row>
        <ion-row class='gutter-lg'>
        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating'>Zipcode:</ion-label>
            <ion-input type='text' maxlength='6' alphaNumeric formControlName='postalCode'></ion-input>
            <ion-note *ngIf="isTouched('postalCode')" slot='error'>You must enter a valid zipcode.</ion-note>
          </ion-item>
        </ion-col>
    </ion-row>
    <ion-row class='gutter-lg'>

        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating'>Phone 1:</ion-label>
            <ion-input type='text' formatType='phone' maxlength='12' numberOnly
                       formControlName='phone1'></ion-input>
            <ion-note *ngIf="isTouched('phone1')" slot='error'>You must enter a valid phone number.</ion-note>
          </ion-item>
        </ion-col>
        <ion-col size='12' size-md='6'>
          <ion-item counter='true'>
            <ion-label position='floating'>Phone 1 Notes:</ion-label>
            <ion-input appRestrictSpace formControlName='phone1Notes'
                       maxlength='100' type='text'
                       placeholder='example: when should this number be used'></ion-input>
          </ion-item>
        </ion-col>

        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating'>Phone 2:</ion-label>
            <ion-input type='text' numberOnly formatType='phone' formControlName='phone2' maxlength='12'></ion-input>
            <ion-note *ngIf="isTouched('phone2')" slot='error'>You must enter a valid phone number.</ion-note>
          </ion-item>
        </ion-col>
        <ion-col size='12' size-md='6'>
          <ion-item counter='true'>
            <ion-label position='floating'>Phone 2 Notes:</ion-label>
            <ion-input appRestrictSpace formControlName='phone2Notes'
                       maxlength='100' type='text'
                       placeholder='example: when should this number be used'></ion-input>
          </ion-item>
        </ion-col>

        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating'>Email:</ion-label>
            <ion-input type='email' formControlName='email'></ion-input>
            <ion-note *ngIf="isTouched('email')" slot='error'>You must enter a valid Email.</ion-note>
          </ion-item>
        </ion-col>

        <ion-col size='12' size-md='6'>
          <app-date-picker [selectedDates]='memberForm?.value?.birthdate?[memberForm.value.birthdate]:[]'
                           showHeadbar='Date of Birth :'
                           pickerType='date' [maxValue]='maxDate'
                           [showReset]='false'
                           (selectedRange)='memberForm.controls.birthdate.setValue($event)'
                           placeHolder=''>
          </app-date-picker>
        </ion-col>
      </ion-row>
      <ion-card-content class='detail-form__notes'>
        <app-note-list class='ion-text-left' *ngIf='utils.isFDRole()'
                       [id]='memberDetail?._id ? memberDetail?.ownerNumber : null' [noteList]='memberDetail?.notes'
                       (updatedNotes)='updateNotes($event)'></app-note-list>
      </ion-card-content>

      <ion-card-content class='detail-form__action flex ion-justify-content-end'>
        <ion-label>
          <app-button buttonText='Cancel' color='dark' (buttonClick)='cancel()'></app-button>
          <app-button buttonText='Add' [disabled]='!hasRoleAccess' color='success' (buttonClick)='save()'></app-button>
        </ion-label>
      </ion-card-content>
    </form>
  </ng-container>
</ion-card>
