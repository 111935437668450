<ion-card class='top-header flex ion-wrap ion-align-items-center ion-justify-content-center' [ngClass]='className'
          style='--width:{{width}}'>
  <ion-searchbar class='search-bar' *ngIf='placeholder'
                 [placeholder]='placeholder'
                 #Search
                 debounce='500'
                 appRestrictSpace restrictValue='(\[])'
                 show-clear-button='focus'
                 (ionChange)='searched.next(Search.value)'>
  </ion-searchbar>
  <app-button *ngIf='buttonText' [buttonText]='buttonText' color='primary' [iconSlot]='buttonIconSlot'
              [iconName]='buttonIconName' (buttonClick)='buttonClick.next(true)'></app-button>
</ion-card>
