<ion-card>
  <ion-card-header>
    <ion-card-title>#{{ticketDetail?.ticketNumber|| 'New Ticket'}}</ion-card-title>
    <ion-card-subtitle [hidden]="mode === 'CREATE'">Created by <strong>{{ ticketDetail.createdBy }}</strong> on
      {{ ticketDetail?.taskDetails?.created | date: 'medium' }}
    </ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <form [formGroup]="ticketForm">
      <ion-grid class="ion-padding">
        <ion-row>
          <ion-col size-sm="12">
            <ion-item>
              <ion-label position="floating">Ticket Type:</ion-label>
              <ion-select formControlName="category">
                <ion-select-option *ngFor="let category of categories" value="{{ category.type }}">{{ category.type }}
                </ion-select-option>
              </ion-select>
              <ion-note *ngIf="isTouched('category')" slot="error">You must enter Ticket Type
              </ion-note>
            </ion-item>
            <ion-item>
              <ion-label position="floating">Status:</ion-label>
              <ion-select formControlName="status">
                <ion-select-option value="open">open
                </ion-select-option>
                <ion-select-option value="closed">closed
                </ion-select-option>
              </ion-select>
              <ion-note *ngIf="isTouched('status')" slot="error">You must enter details for the ticket Status
              </ion-note>
            </ion-item>
            <ion-item>
              <ion-label position="floating">Location:</ion-label>
              <ion-input type="text" formControlName="site"></ion-input>
              <ion-note *ngIf="isTouched('site')" slot="error">You must enter location details
              </ion-note>
            </ion-item>
            <ion-item [hidden]="mode === 'CREATE'">
              <ion-label position="floating">Created By:
              </ion-label>
              <ion-input type="text" formControlName="createdBy" [readonly]="true"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="floating">Select staff member
              </ion-label>
              <ion-select formControlName="assignedTo">
                <ion-select-option *ngFor="let staff of staffMembers" value="{{ staff.first }} {{ staff.last }}">
                  {{ staff.first }} {{ staff.last }}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item counter="true">
              <ion-label position="floating">Enter Ticket Details</ion-label>
                <ion-textarea rows="5" maxlength="100" rows="4" cols="10" formControlName="request">
                </ion-textarea>
              <ion-note *ngIf="isTouched('request')" slot="error">You must enter details for the ticket
              </ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </ion-card-content>
  <ion-toolbar>
    <ion-row>
      <ion-col>
        <app-button buttonText="Cancel" color="danger" (buttonClick)="cancel()"></app-button>
      </ion-col>
      <ion-col class="ion-text-right">
        <app-button buttonText="Save" color="success" (buttonClick)="save()"></app-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-card>
