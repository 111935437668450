import { Component, OnInit , EventEmitter, Output} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-credit',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.scss'],
})
export class AddCreditComponent implements OnInit {

  @Output() onclose: EventEmitter<void> = new EventEmitter();
  @Output() addCredits: EventEmitter<{ credits: number, reason: string }> = new EventEmitter();

  creditForm = this.fb.group({
    credits: ['', Validators.compose([Validators.required, Validators.min(0.01)])],
    reason: ['', Validators.compose([ Validators.required])]
  });

  constructor(
    private fb: FormBuilder
  ) { }


  ngOnInit() {}



  public cancelClicked(){
    this.onclose.emit();
  }

  public addCharges(){
    this.addCredits.emit(this.creditForm.value);
  }

}
