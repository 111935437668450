import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Note } from 'src/app/core/models/reservation';
import { MembersService } from 'src/app/core/services/members.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss'],
})
export class NoteListComponent implements OnInit {

  @Input() id: string;
  @Input() type: 'members'|'reservations' = 'members';
  @Input() noteList: Note[] = [];
  @Output() updatedNotes: EventEmitter<Note[]> = new EventEmitter();

  public addNew: boolean = false;
  public editingNote: Note;
  public editingIndex: number;

  constructor(
    private utils: UtilService,
    private loadingController: LoadingController,
    private memberService: MembersService
    ) { }

  ngOnInit() {}


  editNote( note: Note, index: number){
    this.addNew = false;
    this.editingIndex = index;
    this.editingNote = {...note};
  }

  addNote() {
      if(this.addNew){
          return;    
      }
      this.addNew = true;
      this.editingIndex = null;
      this.editingNote = {} as Note;
  }

  cancelEdit(){
    this.addNew = false;
    this.editingNote = null;
    this.editingIndex = null;
  }

  async updateNote() {
    if( this.addNew && !this.editingNote?.text?.trim() ){
        this.utils.showToast('Please enter some valid text');
        return;
    }

    const payload = { ...this.editingNote };

    if( !payload?.text?.trim() ){
        payload.text = '';
    }

    if( this.id ){
        const loadingPayment = await this.loadingController.create({
            message: this.addNew? 'Adding Note...' : 'Updating Note...',
        });
        await loadingPayment.present();

        this.memberService
            .upsertNotes(this.id, payload, this.addNew, this.type )
            .then((res) => {
                this.noteList = res;
            })
            .catch((err) => {
                try{
                    const errMessage = err?.error ? JSON.parse( err?.error )?.message : 'Something went wrong';
                    this.utils.showToast( errMessage );
                  }catch( error ){
                    this.utils.showToast( err?.error ? err?.error : "Something went wrong" );
                  }
            })
            .finally(async () => {
                this.addNew = false;
                this.editingNote = null;
                this.editingIndex = null;
                this.updatedNotes.next( this.noteList );
                await loadingPayment.dismiss();
            });
    } else {
        if( this.addNew ){
            this.editingNote.createdAt = new Date().getTime().toString();
            this.noteList = this.noteList ? [  {...this.editingNote}, ...this.noteList ] : [  {...this.editingNote} ] ;
        } else {
            if( !this.editingNote.text.trim() ){
                this.noteList.splice(this.editingIndex,1);
            } else{
                this.editingNote.createdAt = new Date().getTime().toString();
                this.noteList[this.editingIndex] = {...this.editingNote};
            }
        }
        this.updatedNotes.next( this.noteList );
        this.addNew = false;
        this.editingNote = null;
        this.editingIndex = null;
    }
  }

}
