  <ngx-skeleton-loader  *ngIf="type === 'bar'" class="bar" count="1" [appearance]="" [theme]="{  
      'border-radius': '6px',
      height: height,
      width: width,
      margin: margin,
      padding: padding
      }" animation="progress">
  </ngx-skeleton-loader>
  
  <ngx-skeleton-loader *ngIf="type === 'fill'" class="fill" count="1" [appearance]="" [theme]="{  
    'border-radius': '6px',
    height: height,
    width: width,
    margin: margin,
    padding: padding
    }" animation="progress">
</ngx-skeleton-loader>
