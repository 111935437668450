import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
    @Output() closed: EventEmitter<boolean> = new EventEmitter();
}
