

  <ion-card class="add-credit popup-block__wrap">

    <form [formGroup]="creditForm">
      <ion-card-content>
          <ion-label class="add-credit__title h2" color="black">Add Credits:</ion-label>
      </ion-card-content>

      <ion-item>
        <ion-label position="floating">Enter Amount:</ion-label>
        <ion-input placeholder="Amount" type="number" formControlName="credits"></ion-input>
        <ion-note *ngIf="creditForm.touched && creditForm.get('credits').hasError('required')" slot="error">
        Credit is required
        </ion-note>
    </ion-item>
    <ion-item>
        <ion-label position="floating">Enter Reason :</ion-label>
        <ion-input placeholder="Reason" type="text" formControlName="reason"></ion-input>
        <ion-note *ngIf="creditForm.touched && creditForm.get('reason').hasError('required')" slot="error">
        Reason is required
        </ion-note>
    </ion-item>
        <ion-card-content class="popup-block__footer" >
          <app-button buttonText="Cancel" color="primary" (buttonClick)="cancelClicked()"></app-button>
          <app-button buttonText="Add Credits" [disabled]="creditForm.invalid" color="success" (buttonClick)="addCharges()"></app-button>
      </ion-card-content>
    </form>
  </ion-card>


