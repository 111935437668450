import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'app-ticket-filter',
    templateUrl: './ticket-filter.component.html',
    styleUrls: ['./ticket-filter.component.scss'],
})
export class TicketFilterComponent implements OnInit {
    @Input() selectedDates: string[]=[];
    @Input() showCross: boolean = true;
    @Input() dateRangeLabel : string = '';

    @Output() selectedRange: EventEmitter<string[]> = new EventEmitter();

    public today =  moment().startOf('day').format('MMM DD, YYYY');
    constructor() {}

    ngOnInit() {}

}
