export const environment = {
  production: true,
  envName: 'production',
  version: '1.30.0',
  sentryUrl: 'https://4ca0254373b24b00bbc271ffd50721b0@o1375423.ingest.sentry.io/6752279',
  appUrl: 'https://hart-ranch-production.herokuapp.com/',
  imageUploadUrl: 'https://kdfo9vhxxg.execute-api.us-east-1.amazonaws.com/prod',
  openApiUrl: 'https://us-west-2.aws.data.mongodb-api.com/app/hart-ranch-prod-services-myrfm/endpoint',
  constants:{
    // eslint-disable-next-line @typescript-eslint/naming-convention
    APP_ID: 'hart-ranch-prod-services-myrfm',
    stripePublicKey: 'pk_live_51LmdyDAwaTFPIrlVUPBHpIxLwlOypQRX6aKKOYfFQYBXOCJd71hbFbgMXY2aXCMSdHnDIdeH0etzaSEMRbdsp4dc00WRS7ltN6',
    tourismTaxRate: 0.015,
    salesTaxRate: 0.042
  }
};