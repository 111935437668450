import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { hasRoleAccess } from 'src/app/core/helpers/helpers';
import { Member } from 'src/app/core/models/member';
import { Note } from 'src/app/core/models/reservation';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { MembersService } from 'src/app/core/services/members.service';
import { UtilService } from 'src/app/core/services/util.service';
import { EmailValidator } from 'src/app/core/validators/email';

@Component({
  selector: 'app-associate-form',
  templateUrl: './associate-form.component.html',
  styleUrls: ['./associate-form.component.scss'],
})
export class AssociateFormComponent implements OnInit {

    @Input() selectedAssociates: Member[] = [];
    @Input() editIndex: number = -1;
  set memberDetail( member: Member){
    this._memberDetail = member;
    this.memberForm.reset();
    this.setupValidator();
  }

  get memberDetail(): Member{
        return this._memberDetail ;
  }

  @Output() cancelEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() saveEvent: EventEmitter<Member> = new EventEmitter();

  userData: User;
  addAssociate = false;
  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString();
  public memberForm: FormGroup;
  private _memberDetail: Member = {} as Member;

  constructor(
      public alertController: AlertController,
      public utils: UtilService,
      private formBuilder: FormBuilder,
      private memberService: MembersService,
      private loadingController: LoadingController,
      private authService: AuthService) {
      this.userData = this.authService.getUserData();
  }

  get hasRoleAccess() {
      return hasRoleAccess('MEMBER_SERVICE-ADD_ASSOCIATE', this.userData?.roles);
  }

  ngOnInit() {
    this.setupValidator();
  }

  public cancel() {
      this.cancelEvent.emit(true);
  }

  public async save(): Promise<void> {
      this.memberForm.markAllAsTouched();
      if (this.memberForm.invalid) {
          return;
      } else {
          const payload = {...this.memberForm.value, notes: this.memberDetail ? this.memberDetail?.notes : [] };

          if( this.editIndex < 0 && this.selectedAssociates.some( associate => associate.ownerNumber === (payload.ownerNumber || this.memberDetail?.ownerNumber) ) ){
            this.utils.showToast('Associate with provided number already exists');
            return;
          }

            const loadingPayment = await this.loadingController.create({
                message: 'Verifying Details...',
            });
            await loadingPayment.present();

            // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/dot-notation
            if(this.memberDetail && this.memberDetail._id){
                // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/dot-notation
                payload['_id'] = this.memberDetail._id;
                payload.parentId = this.memberDetail?.ownerNumber;
                payload.ownerNumber = this.memberDetail?.ownerNumber;
                // payload.membershipStatus = this.memberDetail?.membershipStatus || false;
            }

            await this.memberService.uniqueDataCheck( payload ).then((res)=>{
                if(res){
                    this.saveEvent.emit(payload);
                } else{
                    this.utils.showToast('Associate with provided number already exists');
                }
            })
            .catch( ( error ) => {
                try{
                    const errMessage = error?.error ? JSON.parse( error?.error )?.message : 'Something went wrong';
                    this.utils.showToast( errMessage );
                }catch( error ){
                    this.utils.showToast( error?.error ? error?.error : "Something went wrong" );
                }
            })
            .finally(async ()=>{
                await loadingPayment.dismiss();
            });
      }
  }

  public isTouched(field: string): boolean{
      return this.memberForm.controls[field].touched;
  }

  public initForm( details: Member ){
    this.memberDetail = details;
  }

  private setupValidator() {
      this.memberForm = this.formBuilder.group({
          ownerNumber: [{
              value: this.memberDetail?.ownerNumber || '',
              disabled: (!this.hasRoleAccess || (this.memberDetail && this.memberDetail?._id)),
          }, [Validators.required]],
        associateType: [this.memberDetail?.associateType || 'Associate', [Validators.required]],
          firstName: [{
              value: this.memberDetail?.firstName || '',
              disabled: !this.hasRoleAccess,
          }, [Validators.required, Validators.minLength(2)]],

          lastName: [
              {
                  value: this.memberDetail?.lastName || '',
                  disabled: !this.hasRoleAccess,
              },
              [Validators.required, Validators.minLength(2)]
          ],
          address: [
              {
                  value: this.memberDetail?.address || '',
                  disabled: !this.hasRoleAccess,
              },
              [Validators.required, Validators.minLength(2)]
          ],
          address2: [
              {
                  value: this.memberDetail?.address2 || '',
                  disabled: !this.hasRoleAccess,
              },
              [Validators.minLength(2)]
          ],
          city: [
              {
                  value: this.memberDetail?.city || '',
                  disabled: !this.hasRoleAccess,
              },
              [Validators.required, Validators.minLength(2)]
          ],
          state: [
              {
                  value: this.memberDetail?.state || '',
                  disabled: !this.hasRoleAccess,
              },
              [Validators.required, Validators.minLength(2)]
          ],
          postalCode: [
              {
                  value: this.memberDetail?.postalCode || '',
                  disabled: !this.hasRoleAccess,
              },
              [Validators.required, Validators.minLength(5)]
          ],
          phone1: [
              {
                  value: this.memberDetail?.phone1 || '',
                  disabled: !this.hasRoleAccess,
              },
              [Validators.required, Validators.minLength(12)]
          ],
          phone1Notes: [{
              value: this.memberDetail?.phone1Notes,
              disabled: !this.hasRoleAccess,
          }],
          phone2: [
              {
                  value: this.memberDetail?.phone2 || '',
                  disabled: !this.hasRoleAccess,
              },
              [Validators.minLength(12)]
          ],
          phone2Notes: [{
              value: this.memberDetail?.phone2Notes,
              disabled: !this.hasRoleAccess,
          }],
          email: [
              {
                  value: this.memberDetail?.email || '',
                  disabled: !this.hasRoleAccess,
              },
              [Validators.required, EmailValidator.isValid]
          ],
          birthdate: [
              {
                  value: this.memberDetail?.birthdate || '',
                  disabled: !this.hasRoleAccess,
              }
          ],
          membershipStatus: [
              {
                  value: this.memberDetail?.membershipStatus || false,
                  disabled: !this.hasRoleAccess,
              }
          ]
      });
  }

  updateNotes( notes: Note[] ){
    this.memberDetail.notes = notes || [];
  }
}
