import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageToUpload } from 'src/app/core/models/image.modal';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {

  @Input() imageList: string[]=[];
  @Input() imageToUploadList: ImageToUpload[]=[];
  @Input() id: string = '';
  @Input() slideBreakPoint = {};
  @Input() imageCount = 2;
  @Input() showCross = true;
  @Output() deleteImageClicked: EventEmitter<{index: number; type: string}> = new EventEmitter();

  public showLoader = true;
  public slideOpts: any;

  constructor() { }

  ngOnInit() {
    this.id = this.id.trim().toLowerCase().replace( /[\W_]+/g, '' );
    this.slideOpts = {
        keyBoardControl: false,
        slidesPerView: this.imageCount,
        navigation: {
            nextEl: '.swiper-button-next'+this.id,
            prevEl: '.swiper-button-prev'+this.id,
        },
        observer: true,
        spaceBetween: 10,
        observeParents: true,
        parallax: true,
        simulateTouch: true,
        watchSlidesProgress: true,
        a11y: {
            enabled: true,
            firstSlideMessage: 'hello',
        },
        keyboard: true,
        mousewheel: true,
        breakpoints: this.slideBreakPoint,
      };
  }

}
