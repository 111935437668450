import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-display-card',
  templateUrl: './display-card.component.html',
  styleUrls: ['./display-card.component.scss'],
})
export class DisplayCardComponent implements OnInit {

  @Input() showDelete = false;
  @Input() showEdit = true;
  @Input() header: string;
  @Input() body: string;
  @Input() bodyJson: {key: string;value: string}[] = [];
  @Input() footer: {key: string;value: string}[] = [];
  @Output() editClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() deleteClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() cardClicked: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  public editClick(){
    this.editClicked.next(true);
  }

  public cardClick(){
    this.cardClicked.next(true);
  }

  public onDeleteClicked(){
    this.deleteClicked.next(true);
  }
}
