import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FolioCharge } from 'src/app/core/models/folio';

@Component({
    selector: 'app-use-credit',
    templateUrl: './use-credit.component.html',
    styleUrls: ['./use-credit.component.scss'],
})
export class UseCreditComponent implements OnInit {
    @Input() folioItem!: FolioCharge;
    @Input() maxCredits = 0;
    @Output() onclose: EventEmitter<void> = new EventEmitter();
    @Output() addCredits: EventEmitter<{ credits: number; itemId: string}> = new EventEmitter();
    creditForm!: FormGroup;

    constructor(private fb: FormBuilder) {}

    get maxCreditsThatCanBeUsed() {
      if(!this.folioItem){
        return 0;
      }
      const payableAmpount = this.payableAmount;
      return  parseFloat(Math.min(payableAmpount, this.maxCredits).toFixed(2));
    }


    get payableAmount() {
      if (
        new Date('2024-01-01').getTime() - new Date().getTime() > 0 &&
        this.folioItem.items[0].details.type === 'membership' &&
        this.folioItem.items[0].details.memberType === 'member'
    ){
      const principalAmount = parseFloat(((this.folioItem?.amount || 0) +
      (this.folioItem?.salesTax || 0) + (this.folioItem?.tourismTax || 0)
      ).toFixed(2));

      const principalAmountAfterDiscount =  parseFloat((principalAmount * 95 / 100).toFixed(2));
      return parseFloat(((principalAmountAfterDiscount || 0) - (this.folioItem?.totalPaidAmount || 0)).toFixed(2));
    }else{
      return parseFloat(((this.folioItem?.amount || 0) +
      (this.folioItem?.salesTax || 0) + (this.folioItem?.tourismTax || 0)
      - (this.folioItem?.totalPaidAmount || 0)).toFixed(2));
    }
  }

    ngOnInit() {
      const payableAmpount = this.payableAmount;
      const min = parseFloat(Math.min(payableAmpount, this.maxCredits).toFixed(2));
      this.creditForm = this.fb.group({
            credits: [min, Validators.compose([Validators.min(0), Validators.max(min)])],
      });
    }

    public cancelClicked() {
        this.onclose.emit();
    }

    public addCharges() {
        this.addCredits.emit({ credits: this.creditForm.get('credits').value, itemId: this.folioItem.uniqueId });
    }
}
