<ion-card class="custom-price popup-block__wrap">
    <ion-label class="custom-price__title h2" color="black">Amount to Pay:</ion-label>
    <form *ngIf="creditForm" [formGroup]="creditForm">
        <ion-item>
            <ion-label position="floating"> Amount to Pay: </ion-label>
            <ion-input
                formControlName="price"
                placeholder="Custom Amount"
                maxlength="9"
                type="text"
                numberOnly
                [hasDecimal]="true"
                [limitAfterDecimal]="2"
            ></ion-input>
        </ion-item>

        <ion-label class="custom-price__discountText" color="primary" *ngIf="showDiscountMessage">You are eligible for 5% discount</ion-label>

        <ion-card-content class="popup-block__footer" >
          <app-button type="submit" buttonText="Confirm" [disabled]="creditForm.invalid" color="success" (buttonClick)="submitForm()"></app-button>
      </ion-card-content>
    </form>


</ion-card>
