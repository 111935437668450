import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
    selector: 'app-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit {
    @Output() cancelEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() saveEvent: EventEmitter<any> = new EventEmitter();
    submitted: boolean = false;
    public resetForm: FormGroup;
    ngOnInit() {
        this.resetForm = new FormGroup(
            {
                newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
                confirmPassword: new FormControl('', [Validators.required,this.validatePass('newPassword')]),
            },
        );
    }

    cancel() {
        this.cancelEvent.emit(true);
    }

    isTouched(field: string): boolean {
        return this.resetForm?.controls[field].touched;
    }

    hasError(field: string, error: string) {
        return this.resetForm?.controls[field]?.errors && this.resetForm?.controls[field]?.errors[error];
    }

    submit() {
        this.submitted = true;
        if (this.resetForm.invalid) {
            return false;
        }
        this.saveEvent.emit({ newPassword: this.resetForm.value.newPassword, confirmPassword: this.resetForm.value.confirmPassword });;
    }

    public validatePass(newpassword: string): ValidatorFn {
      return (control: FormControl) => {
        if (!control || !control.parent) {
          return null;
        }
        return control.parent.get(newpassword).value === control.value
          ? null
          : { notMatched: true };
      };
    }

}
