import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { hasRoleAccess } from '../helpers/helpers';
import { AuthService } from '../services/auth.service';

@Directive({
    selector: '[roleAccess]',
})
export class RoleAccessDirective implements OnInit {
    @Input() action: string;
    userData;
    type;
    constructor(private el: ElementRef, private authService: AuthService) {
        this.userData = this.authService.getUserData();
        this.authService.userData$.subscribe((res) => {
            this.userData = res;
            this.updateAccess('subject');
        });
    }

    ngOnInit() {
        this.updateAccess('init');
    }

    updateAccess(origin: string) {
        const hasAccess = hasRoleAccess(this.action, this.userData?.roles);
        if (origin === 'subject') {
            if (hasAccess) {
                this.addElement();
            } else {
                this.removeElement();
            }
        } else {
            if (!hasAccess) {
                this.removeElement();
            }
        }
    }

    private removeElement() {
        this.el.nativeElement.classList.add('removed');
    }

    private addElement() {
        this.el.nativeElement.classList.remove('removed');
    }
}
