import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {

  @Input() className: string='ion-text-center';
  @Input() iconName: string='error.svg';
  @Input() headerText: string = '';
  @Input() descriptionText: string;
  @Input() descriptionWidth: string='100%';
  @Input() nextButtonText: string;
  @Input() cancelButtonText: string;
  @Input() disableNextButton = false;
  @Input() isModalOpen = false;
  @Output() modalClosed: EventEmitter< 'next'|'cancel'|'close' > = new EventEmitter();
  constructor() { }

  public buttonClicked: 'next'|'cancel'|'close' = 'close';

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }

  onClosed(){
    this.buttonClicked = 'close';
    this.isModalOpen = false ;
  }

  onCancelled(){
    this.buttonClicked = 'cancel';
    this.isModalOpen = false ;
  }

  onNextClicked(){
    this.buttonClicked = 'next';
    this.isModalOpen = false ;
  }

  onModalClose(){
    this.modalClosed.next( this.buttonClicked );
  }

  ngOnInit() {}

}
