import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnInit {

  @Input() height = '100%';
  @Input() width = '100%';
  @Input() radius = '6px';
  @Input() margin = '0';
  @Input() padding = '0';
  @Input() type: 'circle' | 'fill' | 'bar' = 'fill';
  constructor() {}

  ngOnInit(): void {}
}
