import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FolioCharge } from 'src/app/core/models/folio';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
    selector: 'app-custom-price',
    templateUrl: './custom-price.component.html',
    styleUrls: ['./custom-price.component.scss'],
})
export class CustomPriceComponent implements OnInit, OnDestroy {

    @Input() selectedItem!: FolioCharge;
    @Output() addItem: EventEmitter<{ price: number}> = new EventEmitter();
    formValueSubscription!: Subscription;
    creditForm: FormGroup;
    math = Math;
    constructor(
        private fb: FormBuilder,
        private utils: UtilService
    ) {}

    get showDiscountMessage(){
      const value = parseFloat((+this.creditForm?.value?.price || 0).toFixed(2));
      if (
        new Date('2024-01-01').getTime() - new Date().getTime() > 0 &&
        value === this.payableAmount &&
        this.selectedItem.items[0].details.type === 'membership' &&
        this.selectedItem.items[0].details.memberType === 'member'
    ) {
        return true;
    } else {
        return false;
    }
    }

    get payableAmount() {
      if (
        new Date('2024-01-01').getTime() - new Date().getTime() > 0 &&
        this.selectedItem.items[0].details.type === 'membership' &&
        this.selectedItem.items[0].details.memberType === 'member'
    ){
      const principalAmount = parseFloat(((this.selectedItem?.amount || 0) +
      (this.selectedItem?.salesTax || 0) + (this.selectedItem?.tourismTax || 0)
      ).toFixed(2));
      const principalAmountAfterDiscount =  parseFloat((principalAmount * 95 / 100).toFixed(2));
      return parseFloat(((principalAmountAfterDiscount || 0) - (this.selectedItem?.totalPaidAmount || 0)).toFixed(2));
    }else{
      return parseFloat(((this.selectedItem?.amount || 0) +
      (this.selectedItem?.salesTax || 0) + (this.selectedItem?.tourismTax || 0)
      - (this.selectedItem?.totalPaidAmount || 0)).toFixed(2));
    }

    }
    ngOnInit() {
            this.creditForm = this.fb.group({
                price: [0,
                  Validators.compose([Validators.required, Validators.min(0.5), Validators.max(this.payableAmount)])],
            });

            this.formValueSubscription = this.creditForm.valueChanges.subscribe((e) => {
                this.onPriceEntered(+e.price);
            });
    }

    public onPriceEntered(value) {
        const customAmount = parseFloat((+value).toFixed(2));
        if (customAmount > this.payableAmount) {
            this.utils.showToast('Payable amount for this folio item cannot be greater than $' + this.payableAmount);
            this.creditForm.patchValue({price:  this.payableAmount});
        }
    }



    public submitForm(){
      this.addItem.emit({ price: +this.creditForm.value.price });
    }

    public ngOnDestroy(): void {
      this.formValueSubscription.unsubscribe();
    }
}
