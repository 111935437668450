import { Injectable } from '@angular/core';
import { RealmService } from './realm.service';
import { BehaviorSubject } from 'rxjs';
import { BlackoutDates, BookingSitePayload, GetBookingSitesPayload, Note, PaymentDetailsResponse, Reservation } from '../models/reservation';
import { Site } from '../models/site';
import { FolioCharge, Folios } from '../models/folio';
import { UtilService } from './util.service';
import moment from 'moment';
import { Inventory } from '../models/inventory';
import { CheckDetails } from '../models/cheque';
import { RESERVATIONTYPE } from '../enum/reservation';

@Injectable({
    providedIn: 'root',
})
export class ReservationsService {
    public reservationSearchList: BehaviorSubject<any> = new BehaviorSubject([]);
    public reservationDailyList: BehaviorSubject<any> = new BehaviorSubject([]);
    constructor(private realmService: RealmService, private utils: UtilService) {}

    public async getBookingSites(
        {
            startDate,
            endDate,
            membershipId= null,
            pagination,
            type='valid',
            filters = null,
            memberId=null,
            reservationNumber=null,
            overWriteRule = false,
            skipReservation = null,
            reservationType = RESERVATIONTYPE.MEMBER
        } : GetBookingSitesPayload
    ): Promise< BookingSitePayload > {

        const payload: GetBookingSitesPayload = {
            startDate: this.utils.dateToStringLocal(startDate) || this.utils.dateToStringLocal(endDate),
            endDate: this.utils.dateToStringLocal(endDate) || this.utils.dateToStringLocal(startDate),
            membershipId: membershipId ? membershipId.toString() : null,
            pagination,
            type,
            filters,
            memberId,
            reservationNumber,
            overWriteRule,
            skipReservation,
            reservationType
        };
        return this.realmService.realmApp?.currentUser?.functions.getBookingSites( payload ).then( (res: BookingSitePayload) => {
            res.sites = res.sites.map( site => {
                site.siteRates = site.siteRates.map(rate => {
                    rate.startDate = this.utils.formatUtcToLocalString(rate.startDate);
                    rate.endDate = this.utils.formatUtcToLocalString(rate.endDate);
                    return rate;
                });
                return site;
            });
            return res;
        });
    }

    public async loadDailyReservations() {
        const payload = moment().startOf('day').format('MMM DD, YYYY');
        const result = await this.realmService.realmApp?.currentUser?.functions.reservationsDailyList(payload);
        result.arrivals= this.removeOffset(result.arrivals);
        result.departures= this.removeOffset(result.departures);
        result.inHouse= this.removeOffset(result.inHouse);
        this.reservationDailyList.next(result);
    }

    public async searchReservations(searchString: string, dateRange: string=null) {
        const payload = {
            searchString,
            dateRange
        };
        let result = await this.realmService.realmApp?.currentUser?.functions.reservationSearch(payload);
        result = this.removeOffset(result);
        this.reservationSearchList.next(result);
    }

    public async reservationDetail(reservationNumber: number): Promise<Reservation> {
        return this.realmService.realmApp?.currentUser?.functions.reservationDetail(reservationNumber).then(res=>this.removeOffset([res])[0]);
    }

    public async checkValidCabinBooking(startDate: string, endDate: string, memberId: string, reservationNumber: number = null ): Promise<any> {
        const payload = {
            reservationNumber,
            startDate: this.utils.dateToStringLocal(startDate),
            endDate: this.utils.dateToStringLocal(endDate),
            memberId
        };
        return this.realmService.realmApp?.currentUser?.functions.isValidCabinBooking(payload);
    }

    public async reservationFolio(ids: string[]): Promise<Folios[]> {
        return this.realmService.realmApp?.currentUser?.functions.getFolio(ids)
        .catch((err)=>{
            try{
              const errMessage = err?.error ? JSON.parse( err?.error )?.message : 'Something went wrong';
              this.utils.showToast( errMessage );
            }catch( error ){
              this.utils.showToast( err?.error ? err?.error : "Something went wrong" );
            }
           });
    }

    public async generateReport(startDate: string, endDate: string, offset: number ): Promise<any[]> {
        return this.realmService.realmApp?.currentUser?.functions.getTransactions( { startDate, endDate , offset} );
    }

    public async updateStatus(reservationNumber: number, updatedStatus: string ): Promise<any[]> {
        return this.realmService.realmApp?.currentUser?.functions.updateReservationStatus( { reservationNumber, updatedStatus } );
    }

    public async getFolioPaymentDetails(reservationNumber: number[], memberId: string, paymentMode: 'online'|'offline' = 'online', onlineRefundAmount:number = 0, offlineRefundAmount: number = 0, chequeList: CheckDetails[]=[] ): Promise<PaymentDetailsResponse> {
        const payload = {baseUrl:window.location.origin, reservationNumber, memberId, paymentMode, onlineRefundAmount, offlineRefundAmount, chequeList};
        return this.realmService.realmApp?.currentUser?.functions.getFolioPaymentDetails( payload );
    }

    public async processRefund(reservationNo: number, chargeDetails: FolioCharge = null, onlineRefundAmount:number = 0, offlineRefundAmount: number = 0 ): Promise<Folios> {
        const payload = { reservationNo, onlineRefundAmount, offlineRefundAmount, updateBalance: true, chargeDetails };
        return this.realmService.realmApp?.currentUser?.functions.processRefund( payload );
    }

    public async deleteChargefromFolio(reservationNumber: number, charge: FolioCharge, onlineRefundAmount: number = 0, offlineRefundAmount: number = 0, isMemberFolio: boolean = false, ownerNumber: string = null ): Promise<any> {
        const payload = { reservationNumber, charge, refundDetails: null, isMemberFolio };
        if( isMemberFolio ){
            payload['ownerNumber'] = ownerNumber;
        }
        if( onlineRefundAmount || offlineRefundAmount ){
            payload.refundDetails = { onlineRefundAmount, offlineRefundAmount };
        }
        return this.realmService.realmApp?.currentUser?.functions.deleteAddOnChargeFromFolio( payload );
    }

    public async getSiteDetailsWithPrice(startDate: string, endDate: string, siteNumber: string[],reservationType:RESERVATIONTYPE = RESERVATIONTYPE.MEMBER): Promise<Site[]> {
        const payload = {
            startDate: this.utils.dateToStringLocal(startDate),
            endDate: this.utils.dateToStringLocal(endDate),
            siteNumber,
            reservationType
        };
        return this.realmService.realmApp?.currentUser?.functions.getSiteDetailsWithPrice( payload ).then( (sites: Site[]) => {
            return sites.map( site => {
                site.siteRates = site.siteRates.map(rate => {
                    rate.startDate = this.utils.formatUtcToLocalString(rate.startDate);
                    rate.endDate = this.utils.formatUtcToLocalString(rate.endDate);
                    return rate;
                });
                return site;
            });
        });
    }

    public async addCharge( payload: {reservationNumber: number; selectedItems : {  inventory: Inventory, chargeAmount: number; chargeQuantity: number}[]} ): Promise<Site[]> {
        return this.realmService.realmApp?.currentUser?.functions.addFolioCharge(payload);
    }

    public async createReservation(reservationNumber: number): Promise<any> {
        return this.realmService.realmApp?.currentUser?.functions.createReservation(reservationNumber);
    }

    public async checkout(reservationNumber: number): Promise<any> {
        return this.realmService.realmApp?.currentUser?.functions.checkout({ reservationNumber });
    }

    public async initiateCreateReservation(reservation: Reservation, addOnList: FolioCharge[] = [] , addOnPayloadData: { inventory: Inventory, chargeAmount: number; chargeQuantity: number}[] = []): Promise<any> {
        const payload = {baseUrl:window.location.origin,...reservation, addOnList, addOnPayloadData };
        return this.realmService.realmApp?.currentUser?.functions.initiateCreateReservation(payload);
    }

    public async isValidReservation(startDate: string,
        endDate: string,
        memberId: string,
        reservationNumber: number=null,
        reservationType: RESERVATIONTYPE = RESERVATIONTYPE.MEMBER
    ): Promise<{isValid: boolean; message: string}> {
        if( [RESERVATIONTYPE.NONMEMBER, RESERVATIONTYPE.RPI].includes(reservationType) ){
            return { isValid: true, message: '' };
        }if( [ RESERVATIONTYPE.NONMEMBER, RESERVATIONTYPE.GUEST ].includes(reservationType) ){
            return this.realmService.realmApp?.currentUser?.functions.isValidReservationNonMember( {startDate: this.utils.dateToStringLocal(startDate), endDate: this.utils.dateToStringLocal(endDate) ,memberId, reservationType } );
        } else {
            return this.realmService.realmApp?.currentUser?.functions.isValidReservation( {startDate: this.utils.dateToStringLocal(startDate), endDate: this.utils.dateToStringLocal(endDate) ,memberId, reservationNumber} );
        }
    }

    public async initateMultipleCreateReservationPayment(
            reservations: Reservation[],
            updateReservation: Reservation[] = null,
            payNow: boolean = null,
            event: {
                onlineRefundAmount: number;
                offlineRefundAmount: number;
            }): Promise<any> {
        const payload = {baseUrl:window.location.origin, reservations, updateReservation, payNow, ...event };
        return this.realmService.realmApp?.currentUser?.functions.multipleCreateAndUpdate(payload);
    }

    public async cancelReservation(reservation: Reservation, onlineRefundAmount: number, offlineRefundAmount: number ): Promise<any> {
        return this.realmService.realmApp?.currentUser?.functions.cancelReservation({...reservation, onlineRefundAmount, offlineRefundAmount});
    }

    public async getBlackoutDates(): Promise<BlackoutDates[]> {
        return this.realmService.realmApp?.currentUser?.functions.getBlackoutDates();
    }

    public async updateReservation(reservation: Reservation,  onlineRefundAmount: number = 0, offlineRefundAmount: number = 0 ): Promise<any> {
        return this.realmService.realmApp?.currentUser?.functions.updateReservation({...reservation, onlineRefundAmount, offlineRefundAmount});
    }

    public async upsertReservationNotes(reservationNumber:number, note: Note, addNew: boolean ): Promise<Reservation> {
        return this.realmService.realmApp?.currentUser?.functions.upsertReservationNotes({reservationNumber, note, addNew});
    }

    public async initiateReservationCheckinPayment(reservation: Reservation, additinalReservation: number[]=[],  onlineRefundAmount: number = 0, offlineRefundAmount: number = 0 ): Promise<any> {
        const payload = {baseUrl:window.location.origin,...reservation, additinalReservation, onlineRefundAmount, offlineRefundAmount};
        return this.realmService.realmApp?.currentUser?.functions.initiateReservationCheckinPayment(payload);
    }

    clearSearch() {
        this.reservationSearchList.next([]);
    }

    private removeOffset(reservations: Reservation[]){
        reservations?.forEach((reservation,i)=>{
            if(reservation){
                reservations[i].checkin=this.utils.formatUtcToLocalString(reservation?.checkin);
                reservations[i].checkout = this.utils.formatUtcToLocalString(reservation?.checkout);
            }
        });
        return reservations;
    }
}
