/* eslint-disable @typescript-eslint/naming-convention */
import { ROLES } from './roles';
export const ACTIONS = {
    SITE: {
        CREATE: {
            [ROLES.ADMIN.value]: true,
        },
        READ: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        BLOCK_SITE: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE: {
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        DELETE: {
            [ROLES.ADMIN.value]: true,
        }
    },
    MAINTENANCE: {
        CREATE: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_BASIC.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        READ: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_BASIC.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_BASIC.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        DELETE: {
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        ASSIGN: {
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        REQUEST_PROPANE: {
            [ROLES.NON_MEMBER.value]: true,
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_BASIC.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
    },
    STORAGE: {
        CREATE: {
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        READ: {
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE: {
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        DELETE: {
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        CREATE_DELETE_24: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
    },
    GROUNDS: {
        CREATE: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.GROUNDS_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        READ: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.GROUNDS_BASIC.value]: true,
            [ROLES.GROUNDS_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.GROUNDS_BASIC.value]: true,
            [ROLES.GROUNDS_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        DELETE: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_MANAGER.value]: true,
            [ROLES.GROUNDS_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
    },
    MAILROOM: {
        LIST: {
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAILROOM.value]: true,
            [ROLES.ADMIN.value]: true,
            [ROLES.MEMBER.value]: true
        },
        READ: {
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAILROOM.value]: true,
            [ROLES.ADMIN.value]: true
        },
        CREATE_FORWARDING: {
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAILROOM.value]: true,
            [ROLES.ADMIN.value]: true,
          [ROLES.MEMBER.value]: true,
        },
        CHARGE_ACCOUNT: {
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAILROOM.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE_FORWARDING: {
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAILROOM.value]: true,
            [ROLES.ADMIN.value]: true,
            [ROLES.MEMBER.value]: true,
        },
        POSTAGE_PAYMENT: {
          [ROLES.RESERVATION_MANAGER.value]: true,
          [ROLES.MAILROOM.value]: true,
          [ROLES.ADMIN.value]: true,
          [ROLES.MEMBER.value]: true,
        },
    },
    HOUSEKEEPING: {
        CREATE: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.HOUSEKEEPING_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        READ: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.HOUSEKEEPING_BASIC.value]: true,
            [ROLES.HOUSEKEEPING_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.HOUSEKEEPING_BASIC.value]: true,
            [ROLES.HOUSEKEEPING_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        DELETE: {
            [ROLES.HOUSEKEEPING_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        INSPECT: {
            [ROLES.HOUSEKEEPING_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true
        },
    },
    RESERVATION: {
        LIST: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.SALES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        CREATE: {
            [ROLES.NON_MEMBER.value]: true,
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.SALES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        READ: {
            [ROLES.NON_MEMBER.value]: true,
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.SALES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE: {
            [ROLES.NON_MEMBER.value]: true,
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.SALES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        DELETE: {
            [ROLES.NON_MEMBER.value]: true,
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.SALES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        REFUND: {
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.SALES.value]: true,
            [ROLES.ADMIN.value]: true
        },
        LOCK: {
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
        }
    },
    FINANCIAL_ACCOUNTING: {
        REVENUE_REPORT: {
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        FOLIO_ACCESS: {
            [ROLES.NON_MEMBER.value]: true,
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MAINTENANCE_ADMIN.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
    },
    SALES: {
        CREATE: {
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        READ: {
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        CANCEL: {
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE: {
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
    },
    MEMBER_SERVICE: {
        CHANGE_NAME: {
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        ADD_ASSOCIATE: {
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        DUES_PAYMENTS: {
            [ROLES.MEMBER.value]: true,
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        LONG_TERM_STORAGE: {
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE_DETAILS: {
            [ROLES.MEMBER.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.SALES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        UPDATE_MEMBERSHIPTYPE: {
            [ROLES.SALES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        CHANGE_STATUS: {
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
        ADD_MEMBER: {
            [ROLES.SALES.value]: true,
            [ROLES.MEMBER_SERVICES.value]: true,
            [ROLES.ADMIN.value]: true,
        },
    },
    ADMIN: {
        ALL: {
            [ROLES.ADMIN.value]: true,
        },
    },
    INHOUSE: {
        BILL: {
            [ROLES.ADMIN.value]: true,
            [ROLES.RESERVATION_BASIC.value]: true,
            [ROLES.RESERVATION_LEAD.value]: true,
            [ROLES.RESERVATION_MANAGER.value]: true,
            [ROLES.SALES.value]: true,
        }
    }
};
