import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { InfiniteScrollCustomEvent, NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member';
import { Paginator, Reservation } from 'src/app/core/models/reservation';
import { MembersService } from 'src/app/core/services/members.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-reservation-simple-list',
  templateUrl: './reservation-simple-list.component.html',
  styleUrls: ['./reservation-simple-list.component.scss'],
})
export class ReservationSimpleListComponent implements OnInit {

  @Input() memberDetails: Member;
  @Output() openReservationEvent: EventEmitter<any> = new EventEmitter();

  public totalReservations: number;
  public reservationList: Reservation[] = [];
  public loadingInitialData: boolean = true;
  public loadingMoreReservation: boolean = false;
  private infiniteLoader: InfiniteScrollCustomEvent;
  private pagination: Paginator = { page: 1, perPage: 20 };

  constructor(
    private navCtrl: NavController,
    private memberService: MembersService,
    private utils: UtilService ) { }

  ngOnInit() {
   this.loadData();
  }

  public getReservations( type: any ){
    this.pagination = { page: 1, perPage: 20 };
    this.loadingInitialData = true;
    this.loadData( type?.detail?.value );
  }

  public loadMoreReservations( event: any ): void {
      if( !this.loadingMoreReservation ){
        this.loadingMoreReservation = true;
        this.pagination.page++;
        this.infiniteLoader = event;
        this.loadData();
      }
    }

  public openReservation(reservationNumber: number) {
    this.navCtrl.navigateRoot('reservations/editor/' + reservationNumber);
  }

  private loadData( type: string = 'past' ){
    this.loadingMoreReservation = true;
    if(this.pagination.page === 1) {
      this.reservationList = [];
    }

    this.memberService.getMemberReservations( this.memberDetails.ownerNumber, type, this.pagination ).then( res => {
      if( res ){
        this.totalReservations = res.totalCount;
        res.reservations.forEach( reservation => this.reservationList.push( reservation ) );
        
      }
    })
    .catch( (err) => { 
      try{
        const errMessage = err?.error ? JSON.parse( err?.error )?.message : 'Something went wrong';
        this.utils.showToast( errMessage );
      }catch( error ){
        this.utils.showToast( err?.error ? err?.error : "Something went wrong" );
      }
    })
    .finally( ()=> {

      if( this.infiniteLoader ){
        this.infiniteLoader.target.complete();
        this.infiniteLoader = null;
      }
      this.loadingMoreReservation = false;
      this.loadingInitialData = false;
    });
  }

}
