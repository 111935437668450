import { Component, OnInit } from '@angular/core';
import { hasRoleAccess } from 'src/app/core/helpers/helpers';
import { User } from '../../../models/user';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {

  public appPages = [];

  constructor(private authService: AuthService) {

  }

  ngOnInit() {

    const userData = this.authService.getUserData();

    if (userData.roles.includes('member') && userData?.memberId && userData?.ownerNumber ) {
      this.appPages = [
        {
          id: 'dashboard-menu-button',
          title: 'Dashboard',
          url: '/member-dashboard',
          icon: 'dashboard',
          action: '',
        },
        // {
        //   id: 'reservation-menu-button',
        //   title: 'Reservations',
        //   url: '/reservations/reservation',
        //   icon: 'calendar',
        //   action: '',
        // },
        // {
        //   id: 'mail-menu-button',
        //   title: 'Mail Forwarding',
        //   url: 'mailboxes/editor/storage/'+ userData?.ownerNumber,
        //   icon: 'mailbox',
        //   action: '',
        // },
        {
          id: 'members-menu-button',
          title: 'My Account',
          url: '/members/editor/'+ userData?.memberId,
          icon: 'id-card',
          action: '',
        },
      ];
    } else {
      this.appPages = [
        {
          id: 'dashboard-menu-button',
          title: 'Dashboard',
          url: '/dashboard',
          icon: 'dashboard',
          action: '',
        },
        {
          id: 'reservation-menu-button',
          title: 'Reservations',
          url: '/reservations',
          icon: 'calendar',
          action: 'RESERVATION-READ',
        },
        // {
        //   id: 'security-menu-button',
        //   title: 'Security',
        //   url: '/security',
        //   icon: 'shield',
        // },
        {
          id: 'maintenance-menu-button',
          title: 'Maintenance',
          url: '/maintenance',
          icon: 'maintenance',
          action: 'MAINTENANCE-READ',
        },
        {
          id: 'grounds-menu-button',
          title: 'Grounds',
          url: '/grounds',
          icon: 'golf',
          action: 'GROUNDS-READ',
        },
        {
          id: 'housekeeping-menu-button',
          title: 'Housekeeping',
          url: '/housekeeping',
          icon: 'housekeeping',
          action: 'HOUSEKEEPING-READ',
        },
        {
          id: 'storage-menu-button',
          title: 'Storage',
          url: '/storage',
          icon: 'storage',
          action: 'STORAGE-READ',
        },
        {
          id: 'site-manager-menu-button',
          title: 'Site Manager',
          url: 'site-manager',
          icon: 'site-manager',
          action: 'SITE-READ',
        },
        {
          id: 'members-menu-button',
          title: 'Members',
          url: '/members',
          icon: 'id-card',
          action: 'MEMBER_SERVICE-ADD_MEMBER',
        },
        {
          id: 'mail-menu-button',
          title: 'Mailboxes',
          url: 'mailboxes',
          icon: 'mailbox',
          action: 'MAILROOM-READ',
        },
        {
          id: 'inhouse-menu-button',
          title: 'House Accounts',
          url: 'inHouse',
          icon: 'inhouse',
          action: 'INHOUSE-BILL',
        },
        {
          id: 'settings-menu-button',
          title: 'Reports',
          url: 'reports',
          icon: 'administrator',
          action: '',
        },
        {
          id: 'settings-menu-button',
          title: 'Administrator',
          url: 'settings',
          icon: 'administrator',
          action: 'ADMIN-ALL',
        },
        // {
        //   id: 'sales-menu-button',
        //   title: 'Sales',
        //   url: '/folder/Sales',
        //   icon: 'cash',
        // },
        // {
        //   id: 'reports-menu-button',
        //   title: 'Reports',
        //   url: '/folder/Reports',
        //   icon: 'bar-chart',
        // },
      ];
    }
  }
}
