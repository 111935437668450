import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { StaffService } from 'src/app/core/services/staff.service';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
    token: string;
    tokenId: string;
    success: boolean;
    constructor(
        private staffService: StaffService,
        private route: ActivatedRoute,
        private loadingController: LoadingController) {
        this.token = this.route.snapshot.queryParamMap.get('token');
        this.tokenId = this.route.snapshot.queryParamMap.get('tokenId');
    }
    async ngOnInit() {
        const loading = await this.loadingController.create({
            message: 'Confirming Email...',
        });
        await loading.present();
        try {
            await this.staffService.confirmUser(this.token, this.tokenId);
            this.success = true;
            loading.dismiss();
        } catch (error) {
            this.success = false;
            loading.dismiss();
        }
    }

    navigateToLogin() {
        window.location.href = '/';
    }
}
