import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicSlides } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MemberDetailFormComponent } from './member-detail-form/member-detail-form.component';
import { ReservationSimpleListComponent } from './reservation-simple-list/reservation-simple-list.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { RestrictSpaceDirective } from '../../directives/restrict-space.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { MediaUploadComponent } from './media-upload/media-upload.component';
import { DragDropDirective } from '../../directives/drag-drop.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ImageComponent } from './image/image.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { RoleAccessDirective } from '../../directives/role-access.directive';
import { RouterModule } from '@angular/router';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TicketEditorFormComponent } from './ticket-editor-form/ticket-editor-form.component';
import { TicketFilterComponent } from './ticket-filter/ticket-filter.component';
import { TicketListItemComponent } from './ticket-list-item/ticket-list-item.component';
import { MbscDatepickerModule } from '@mobiscroll/angular';
import { NumberOnlyDirective } from '../../directives/number-only.directive';
import { ReservationListItemComponent } from './reservation-list-item/reservation-list-item.component';
import { HeaderComponent } from './header/header.component';
import { ButtonComponent } from './button/button.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom, EffectFade, Navigation } from 'swiper';
import { DisplayCardComponent } from './display-card/display-card.component';
import { SearchComponent } from './search/search.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PopupComponent } from './popup/popup.component';
import { PinchZoomModule } from 'ngx-pinch-zoom-13';
import { AssociateFormComponent } from './associate-form/associate-form.component';
import { NoteListComponent } from './note-list/note-list.component';
import { CheckFormComponent } from './cheque-form/cheque-form.component';
import { AddChargesComponent } from './add-charges/add-charges.component';
import { WarningComponent } from './warning/warning.component';
import { AddCreditComponent } from './add-credit/add-credit.component';
import { UseCreditComponent } from './use-credit/use-credit.component';
import { CustomPriceComponent } from './custom-price/custom-price.component';
import { AlphaNumericDirective } from '../../directives/alpha-numeric.directive';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom, EffectFade, Navigation, IonicSlides]);


const COMPONENTS = [
    AddCreditComponent,
    ReservationListItemComponent,
    TicketListItemComponent,
    TicketEditorFormComponent,
    TicketFilterComponent,
    MemberDetailFormComponent,
    ReservationSimpleListComponent,
    ConfirmationComponent,
    ResetPasswordFormComponent,
    PdfViewerComponent,
    MediaUploadComponent,
    DragDropDirective,
    SkeletonComponent,
    ImageComponent,
    DatePickerComponent,
    NumberOnlyDirective,
    AlphaNumericDirective,
    SideBarComponent,
    HeaderComponent,
    ButtonComponent,
    SearchComponent,
    PopupComponent,
    DisplayCardComponent,
    PaymentStatusComponent,
    NoteListComponent,
    CheckFormComponent,
    AddChargesComponent,
    RestrictSpaceDirective,
    WarningComponent,
    UseCreditComponent,
    CustomPriceComponent,
];

@NgModule({
    declarations: [
        RoleAccessDirective,
        AssociateFormComponent,
        CreditCardComponent,
        ...COMPONENTS
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        NgxSkeletonLoaderModule,
        ReactiveFormsModule,
        TextMaskModule,
        PdfViewerModule,
        MbscDatepickerModule,
        RouterModule,
        SwiperModule,
        PinchZoomModule
    ],
    exports: [
        CommonModule,
        PdfViewerModule,
        ...COMPONENTS
    ],
})

export class CommonComponentsModule {}
