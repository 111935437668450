import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { CheckDetails } from 'src/app/core/models/cheque';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
    selector: 'app-check-form',
    templateUrl: './cheque-form.component.html',
    styleUrls: ['./cheque-form.component.scss'],
})
export class CheckFormComponent implements OnInit {

    @Input() showCancel: boolean = true;
    @Input() editIndex: number = -1;
    @Input() set checkDetails(checkInfo: CheckDetails) {
        this._checkDetails = checkInfo;
        this.setupValidator();
    }

    get checkDetails(): CheckDetails {
        return this._checkDetails;
    }

    @Output() cancelEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() checkDetailsChange: EventEmitter< CheckDetails > = new EventEmitter();

    userData: User;
    addAssociate = false;
    today = moment().startOf('day').format('MMM DD, YYYY');

    public checkInfoForm: FormGroup;
    private _checkDetails: CheckDetails = {} as CheckDetails;

    constructor(
        public utils: UtilService,
        private formBuilder: FormBuilder,
        private authService: AuthService,
    ) {
        this.userData = this.authService.getUserData();
    }

    ngOnInit() {
        this.setupValidator();
    }

    public cancel() {
        this.cancelEvent.emit(true);
    }

    public async save(): Promise<void> {
        this.checkInfoForm.markAllAsTouched();
        if (this.checkInfoForm.invalid) {
            return;
        } else {
          const payload = { ...this.checkInfoForm.value };

          if( !payload || !payload?.chequeNumber?.trim() || !payload?.holderName?.trim() || ((payload?.amount && Number(payload?.amount))? false : true ) ){
            this.checkInfoForm.controls.chequeNumber.setValue(payload?.chequeNumber?.trim() || '');
            this.checkInfoForm.controls.holderName.setValue(payload?.holderName?.trim() || '');
            this.checkInfoForm.controls.amount.setValue( (payload?.amount && Number(payload?.amount)) ?  payload?.amount : null );
            this.checkInfoForm.markAllAsTouched();
            return;
          }

          payload.timeStamp = new Date();
          this.checkDetailsChange.next( payload );
        }
    }

    public isTouched(field: string): boolean {
        return this.checkInfoForm.controls[field].touched;
    }

    public initForm(details: CheckDetails) {
        this.checkDetails = details;
    }

    private setupValidator() {
        this.checkInfoForm = this.formBuilder.group({
            chequeNumber: [
                {
                    value: this.checkDetails?.chequeNumber || '',
                    disabled: !this.utils.isFDRole() ,
                },
                [Validators.required],
            ],
            holderName: [
                {
                    value: this.checkDetails?.holderName || '',
                    disabled: !this.utils.isFDRole(),
                },
                [Validators.required],
            ],
            chequeDate: [
                {
                    value: this.checkDetails?.chequeDate || new Date(),
                    disabled: !this.utils.isFDRole(),
                },
                [Validators.required],
            ],
            amount: [
                {
                    value: this.checkDetails?.amount || null,
                    disabled: !this.utils.isFDRole(),
                },
                [Validators.required],
            ]
        });
    }

    public dateUpdated( $event: any , field: string){
        this.checkInfoForm?.controls[field].setValue($event);
        this.checkInfoForm?.controls[field].markAsTouched();
    }
}
