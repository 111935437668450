import { Injectable } from '@angular/core';
import { Inventory } from '../models/inventory';
import { RealmService } from './realm.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryManagerService {

  constructor(private realmService: RealmService) { }

  public async searchInventory(searchText: string, active: boolean = null, exactMatch: boolean = false ): Promise<Inventory[]> {
    return this.realmService.realmApp?.currentUser?.functions.searchInventory( { searchText , active, exactMatch } );
  }

  public async getInventoryDetails(id: string): Promise<string> {
    return this.realmService.realmApp?.currentUser?.functions.getInventoryDetails(id);
  }

  public async updateInventory(inventory: Inventory) {
    return this.realmService.realmApp?.currentUser?.functions.updateInventory(inventory);
  }

  public async createInventory(inventory: Inventory) {
    return this.realmService.realmApp?.currentUser?.functions.createInventory(inventory);
  }

}
