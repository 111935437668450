import { Injectable } from '@angular/core';
import { RealmService } from './realm.service';
import { RealmCollections } from './realm-collections';
import { BehaviorSubject } from 'rxjs';
import { GuestNightDetails, Member, MemberDashboardStats, MemberReservationPayload } from '../models/member';
import { UtilService } from './util.service';
import { Note, Paginator, PaymentDetailsResponse } from '../models/reservation';
import { FolioCharge, Folios } from '../models/folio';
import { CheckDetails } from '../models/cheque';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  public memberList: BehaviorSubject<any> = new BehaviorSubject([]);
  private reservingMember: BehaviorSubject<any> = new BehaviorSubject(null);
  private memberCollection: any;

  constructor(private realmService: RealmService, private utils: UtilService) {
    this.memberCollection = this.realmService.getCollection(
      RealmCollections.members
    );
  }

  public get reservingMemberDetail(){
    return JSON.parse( this.reservingMember.value || {} );
  }

  public async lookupMember(searchString: string): Promise<Member[]> {
    return this.realmService.realmApp?.currentUser?.functions.memberLookup(searchString);
  }

  public async upsertNotes(id: string, note: Note, addNew: boolean = false, type: string ): Promise<Note[]> {
    return this.realmService.realmApp?.currentUser?.functions.upsertNotes({id, note, addNew, type});
  }

  public async getMemberReservations( memberNumber: string, type: string = 'past', pagination: Paginator ): Promise< MemberReservationPayload > {
    return this.realmService.realmApp?.currentUser?.functions.getMemberReservations( {memberNumber, pagination, type} );
  }

  public async getMemberDuesList( memberNumber: string, pagination: Paginator ): Promise< MemberReservationPayload > {
    return this.realmService.realmApp?.currentUser?.functions.getMemberDuesList( {memberNumber, pagination} );
  }

  public async uniqueDataCheck( payload: Member ): Promise< string > {
    return this.realmService.realmApp?.currentUser?.functions.UniqueMemberDetailsCheck( payload );
  }

  public async startMembershipPayment(memberDetails: Member, paymentMode: string): Promise<any> {
    const payload = {baseUrl:window.location.origin, ...memberDetails, paymentMode};
    return this.realmService.realmApp?.currentUser?.functions.startMembershipPayment( payload );
  }

  public async searchParentMembers(searchString : string): Promise<any> {
    return this.realmService.realmApp?.currentUser?.functions.searchPrentMembers(searchString);
  }

  public async getMemberDashboardStats( memberNumber: string ): Promise<MemberDashboardStats> {
    return this.realmService.realmApp?.currentUser?.functions.memberDashboardStats( {ownerNumber: memberNumber} );
  }

  public async getGuestNightLimit( memberNumber: string ): Promise<{result: GuestNightDetails}> {
    return this.realmService.realmApp?.currentUser?.functions.getGuestNightLimit( { memberNumber } );
  }

  public async getMemberFolio( folios: string[], ownerNumber: string ): Promise<{result: Folios[]}> {
    return this.realmService.realmApp?.currentUser?.functions.getMemberFolioDetails( {folios, ownerNumber} );
  }

  public async getMemberFolioDetails( pagination: Paginator,deleted: boolean, ownerNumber: string, folioId: string = null ): Promise<any> {

    const payload = {
      pagination,
      deleted
    };
    if( ownerNumber ){
      payload['ownerNumber'] = ownerNumber;
    } else if( folioId ){
      payload['folioId'] = folioId;
    } else {
      throw new Error('Invalid Details provided');
    }

    return this.realmService.realmApp?.currentUser?.functions.getFolioChargeDetails( payload );
  }

  public async initiateMemberFolioPaymentItemWise( ownerNumber: string, itemList: { itemId: string; price: number; creditsUsed?: number }[], paymentMode: 'online'|'offline' = 'online', onlineRefundAmount:number = 0, offlineRefundAmount: number = 0, chequeList: CheckDetails[]=[] ): Promise<PaymentDetailsResponse> {

    if( !itemList || !itemList?.length ){
      return
    }

    const payload = {
      baseUrl:window.location.origin,
      ownerNumber,
      paymentMode,
      onlineRefundAmount,
      offlineRefundAmount,
      chequeList,
      itemList
    }
    return this.realmService.realmApp?.currentUser?.functions.initiateMemberFolioPaymentItemWise( payload );
  }

  public async deleteAndRefundMemberFolio(ownerNumber: string, charge: FolioCharge, onlineRefundAmount: number = 0, offlineRefundAmount: number = 0 ): Promise<any> {
    const payload = { ownerNumber, charge, refundDetails: null };
    if( onlineRefundAmount || offlineRefundAmount ){
        payload.refundDetails = { onlineRefundAmount, offlineRefundAmount };
    }
    return this.realmService.realmApp?.currentUser?.functions.deleteAndRefundMemberFolio( payload );
  }

  public async folioRefund( ownerNumber: string, charge: FolioCharge, onlineRefundAmount:number = 0, offlineRefundAmount: number = 0 ): Promise<any> {
    const payload = {
      baseUrl:window.location.origin,
      ownerNumber,
      charge,
      onlineRefundAmount,
      offlineRefundAmount
    }
    return this.realmService.realmApp?.currentUser?.functions.processMemberFolioRefund( payload );
  }

  public async memberDetail(memberId: string): Promise<any> {
    const member = await this.realmService.realmApp?.currentUser?.functions.memberDetail(memberId);
    this.reservingMember.next(member);
    return member;
  }

  public async updateMember(member: Member) {
    //if( member.associateDetails.every( associate => ((associate?.ownerNumber != member?.ownerNumber)&&(associate?.email != member?.email)) ) ){
      return this.realmService.realmApp?.currentUser?.functions.updateMember(this.utils.refinePayload(member));
    // } else {
    //   this.utils.showToast('Member number and email must be unique');
    //   return false;
    // }
  }

  public async createMember(member: Member) {
    if( member.associateDetails.every( associate => ((associate?.ownerNumber != member?.ownerNumber)&&(associate?.email != member?.email)) ) ){
      return this.realmService.realmApp?.currentUser?.functions.createMember(this.utils.refinePayload(member));
    } else {
      this.utils.showToast("Member number and email must be unique");
      return false;
    }
  }

  public async getMemberPendingFolio(pageNumber = 1): Promise<any> {
    return this.realmService.realmApp?.currentUser?.functions.getPendingFolioReport({ pageNumber });
  }

  public async getMemberPendingFolioBatches(): Promise<any> {
    return this.realmService.realmApp?.currentUser?.functions.getPendingFolioReportBatches();
  }

  public async addMemberCredits({ memberNumber, amount, reason}: { memberNumber: string, amount: number, reason: string }): Promise<any> {
    return this.realmService.realmApp?.currentUser.functions.addMemberCredits({ memberNumber, amount, reason });
  }

  public async getMemberCredits({ memberNumber}: { memberNumber: string }): Promise<any> {
    return this.realmService.realmApp?.currentUser.functions.getMemberCredits({ memberNumber});
  }

  public async addYearlyMemberDuesForMember(ownerNumber: string): Promise<any> {
    return this.realmService.realmApp?.currentUser.functions.addYearlyMemberDuesForMember({ ownerNumber});
  }

  public async useCreditsForLineItem(ownerNumber: string, itemId: string, creditsUsed: number): Promise<any> {
    return this.realmService.realmApp?.currentUser.functions.useCreditsForLineItems({ ownerNumber, itemId, creditsUsed });
  }
}
