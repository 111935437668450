import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { hasRoleAccess } from '../helpers/helpers';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { RealmService } from '../services/realm.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RoleAccessGuard implements CanActivate {
    userData: User;
    constructor(private router: Router, private authService: AuthService, private realmService: RealmService) {
    }

    canActivate(route: ActivatedRouteSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const userId = this.realmService.realmApp.users[0]?.id;
        if(!userId) {
            this.router.navigate(['/']);
            return false;
        }else{
            const checkToken = localStorage.getItem(`realm-web:app(${environment.constants.APP_ID}):user(` + userId + '):accessToken');
            this.userData = this.authService.getUserData();
            const hasAccess = hasRoleAccess(route.data.action, this.userData?.roles);

            if( checkToken && hasAccess ) {
                if( this.userData?.roles.includes('member') && this.userData?.memberId && this.userData?.ownerNumber && route.data?.page === 'dashboard' ){
                    this.router.navigate(['member-dashboard']);
                  } else if( this.userData && route.data?.page === 'member-dashboard'  ){
                    this.router.navigate(['dashboard']);
                  }
                return true;
            } else if (checkToken && !hasAccess) {
                if( this.userData?.roles.includes('member') && this.userData?.memberId && this.userData?.ownerNumber ){
                    this.router.navigate(['member-dashboard']);
                  } else if( this.userData && !this.userData?.memberId && !this.userData?.ownerNumber ){
                    this.router.navigate(['dashboard']);
                  }
            } else {
               this.router.navigate(['/']);
            }
            return false;
        }
    }
}
