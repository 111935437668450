<ion-card class="check-form">
    <ng-container *ngIf="checkInfoForm">
      <form [formGroup]="checkInfoForm">
          <ion-row >
          <ion-col size="12">
              <ion-item class="floating-note">
                  <ion-label position="floating" position="floating">Name on the check:</ion-label>
                  <ion-input type="text" appRestrictSpace formControlName="holderName"></ion-input>
                  <ion-note *ngIf="isTouched('holderName')" slot="error">You must enter the Name on the check.</ion-note>
              </ion-item>
          </ion-col>
          </ion-row>
          <ion-row >
              <ion-col size="12">
                <ion-item class="floating-note">
                      <ion-label position="floating" position="floating">Check Number:</ion-label>
                      <ion-input type="text" appRestrictSpace [noSpace]="true" formControlName="chequeNumber"></ion-input>
                      <ion-note *ngIf="isTouched('chequeNumber')" slot="error">You must enter a valid check Number.</ion-note>
                  </ion-item>
              </ion-col>
            </ion-row>
            <ion-row >
              <ion-col size="12" size-md="5">
                <ion-item class="floating-note check-form__check">
                      <ion-label position="floating">Check Amount:</ion-label>
                      <ion-input type="text" numberOnly [hasDecimal]="true" [limitAfterDecimal]="2" formControlName="amount"></ion-input>
                      <ion-note *ngIf="isTouched('amount')" slot="error">You must enter a valid amount.</ion-note>
                  </ion-item>
              </ion-col>
              <ion-col size="12" size-md="7">
                <ion-row>
                    <ion-col size="4">
                        <ion-label class="check-form__date" color="dark"> Check Date:</ion-label>
                </ion-col>
                <ion-col size="8">
                    <app-date-picker
                        pickerType="date"
                        placeHolder="Due Date"
                        [showReset]="false"
                        [maxValue]="today"
                        [isInvalid]="(checkInfoForm.controls.chequeDate.invalid && checkInfoForm.controls.chequeDate.touched)?true:false"
                        [selectedDates]="checkInfoForm.value.chequeDate?[checkInfoForm.value.chequeDate]:[]"
                        errorMessage="You must select a valid Dates"
                        (selectedRange)="dateUpdated($event, 'chequeDate')">
                    </app-date-picker>
                </ion-col>
                </ion-row>
            </ion-col>
          </ion-row>
      
          <ion-card-content class="check-form__action flex ion-justify-content-end">
              <ion-label>
                    <ng-container *ngIf="showCancel">
                        <app-button buttonText="Cancel" color="dark" (buttonClick)="cancel()"></app-button>
                    </ng-container>
                    <app-button [buttonText]="editIndex >= 0 ? 'Update' : 'Add'" [disabled]="!utils.isFDRole()" color="success" (buttonClick)="save()"></app-button>
              </ion-label>   
          </ion-card-content>
      </form>
  </ng-container>
</ion-card>