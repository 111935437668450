import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  @Input() placeholder: string;
  @Input() width: string='350px';
  @Input() className: string='';
  @Input() buttonText: string;
  @Input() buttonIconSlot: 'end'|'start';
  @Input() buttonIconName: string;
  @Output() searched: EventEmitter<string> = new EventEmitter();
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

}
