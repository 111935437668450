<ion-card class="notes">

  <ion-toolbar>
    <ion-card-title>
      <ion-text class="h4" color="black"> Notes: </ion-text>
    </ion-card-title>
    <app-button slot="end" buttonText="Add Notes" iconSlot="start" name="mail" color="dark"
      (buttonClick)="addNote()"></app-button>
  </ion-toolbar>

  <ion-card-content>
    <ng-container *ngIf="addNew">
      <ng-container *ngTemplateOutlet="editing"></ng-container>
    </ng-container>
    <ng-container *ngIf="noteList?.length">
      <ng-container *ngFor="let note of noteList;index as i">
        <ng-container *ngIf="editingIndex === i then editing; else list">
        </ng-container>
        <ng-template #list>
          <ion-card-content class="notes__item flex
            ion-justify-content-between">
            <ion-label>{{note.text}}</ion-label>
            <ion-text class="ion-padding-start" (click)="editNote(note, i)">
              <ion-icon src="assets/images/edit.svg"></ion-icon>
            </ion-text>
          </ion-card-content>
        </ng-template>
      </ng-container>
    </ng-container>
  </ion-card-content>
</ion-card>

<ng-template #editing>
  <ng-container *ngIf="editingNote">
    <ion-card class="notes__edit flex">
      <ion-card-content class="notes__edit-input">
        <ion-item>
          <ion-label position="floating">Note:</ion-label>
          <ion-textarea rows="2" [(ngModel)]="editingNote.text" placeholder="Note" type="text"></ion-textarea>
        </ion-item>
      </ion-card-content>
      <ion-card-content class="notes__edit-btn flex ion-flex-wrap
        ion-justify-content-end">
        <app-button buttonText="Cancel" (buttonClick)="cancelEdit()"></app-button>
        <app-button color="success" buttonText="Save" (buttonClick)="updateNote()"></app-button>
      </ion-card-content>
    </ion-card>
  </ng-container>
</ng-template>