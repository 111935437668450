import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { hasRoleAccess } from 'src/app/core/helpers/helpers';
import { Ticket } from 'src/app/core/models/ticket.model';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-ticket-editor-form',
  templateUrl: './ticket-editor-form.component.html',
  styleUrls: ['./ticket-editor-form.component.scss'],
})
export class TicketEditorFormComponent implements OnInit {
  @Input() staffMembers: Array<any>;

  @Input() ticketDetail: Ticket;

  @Input() mode: any;

  @Output() cancelEvent: EventEmitter<boolean> = new EventEmitter();

  @Output() saveEvent: EventEmitter<Ticket> = new EventEmitter();

  public ticketForm = new FormGroup({
    site: new FormControl(''),
    status: new FormControl(''),
    category: new FormControl(''),
    createdBy: new FormControl(''),
    request: new FormControl(''),
    assignedTo: new FormControl('')
  });

  public categories = [
    {
      type: 'maintenance',
    },
    {
      type: 'housekeeping',
    },
    {
      type: 'grounds',
    }
  ];

  private userData : User
  
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.userData = this.authService.getUserData()
    this.setupValidator();
  }

  public isTouched(field: string): boolean {
    return this.ticketForm.controls[field].touched;
  }

  public assignAllowed(): boolean {
    const isMaintenanceCreate = hasRoleAccess('MAINTENANCE-CREATE', this.userData?.roles),
          isMaintenanceUpdate = hasRoleAccess('MAINTENANCE-UPDATE', this.userData?.roles),
          isMaintenanceAssign = hasRoleAccess('MAINTENANCE-ASSIGN', this.userData?.roles);
    if((isMaintenanceCreate || isMaintenanceUpdate) && !isMaintenanceAssign) {
      return false;
    }
    return true;
  }
  
  public cancel() {
    this.cancelEvent.emit(true);
  }

  public save(): void {
    this.ticketForm.markAllAsTouched();
    if (this.ticketForm.invalid) {
    } else {
      const formValues= this.ticketForm.value;
      let assignedFrom= this.ticketDetail?.taskDetails?.assignedFrom||'';
      if(this.ticketDetail?.taskDetails?.assignedTo && this.ticketDetail?.taskDetails?.assignedTo !== formValues.assignedTo){
        assignedFrom= this.ticketDetail.taskDetails.assignedTo;
      }
      const payload = {
        request: formValues.request,
        category: formValues.category,
        taskDetails: {
            due: formValues.due,
            assignedTo: formValues.assignedTo,
            assignedFrom,
            startTime: formValues.startTime,
            endTime: formValues.endTime,
            notes: formValues.notes,
            comments: formValues.comments || [],
            priority: formValues.priority
        },
        status: formValues.status,
        site: formValues.site
      };
      this.saveEvent.emit(payload);
    }
  }

  private setupValidator() {
    this.ticketForm = this.formBuilder.group({
      site: [this.ticketDetail.site, Validators.required],
      status: [this.ticketDetail.status, [Validators.required]],
      category: [this.ticketDetail.category, [Validators.required]],
      createdBy: [this.ticketDetail.createdBy||''],
      created: [this.ticketDetail.taskDetails?.created||new Date()],
      request: [this.ticketDetail.request, [Validators.required]],
      assignedTo: [{
          value: this.ticketDetail.taskDetails?.assignedTo||'',
          disabled : !this.assignAllowed()
        }],
      due: [this.ticketDetail.taskDetails?.due||''],
      startTime: [this.ticketDetail.taskDetails?.startTime||''],
      endTime: [this.ticketDetail.taskDetails?.endTime||''],
      notes: [this.ticketDetail.taskDetails?.notes||''],
      comments: [this.ticketDetail.taskDetails?.comments||[]],
      priority: [this.ticketDetail.taskDetails?.priority||3],
    });
  }
}
