import { ACTIONS } from '../roles/roles-matrix';

export const hasRoleAccess = (action, roles) => {
    let type;
    if (typeof action == 'string' && action.indexOf('-') !== -1) {
        type = action.split('-')[0];
        action = action.split('-')[1];
    } else {
        return true;
    }
    let hasAccess = false;
    if(roles?.length){
      roles.forEach((role) => {
          if (ACTIONS[type][action][role]) {
              hasAccess = true;
          }
      });
    }
    return hasAccess;
};

export const getMonths = () => {
    const monthList = new Array<string>();
    for (let i = 1; i <= 12; i++) {
        let month;
        if (i.toString().length === 1) {
            month = '0' + i.toString();
        } else {
            month = i.toString();
        }
        monthList.push(month);
    }
    return monthList;
};
export const getYears = () => {
    const year = new Date().getFullYear();
    const years = [];
    for (let i = year; i <= year + 20; i++) {
        years.push(i.toString());
    }
    return years;
};
