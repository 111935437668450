
<ion-modal class="popup-block popup-block--backdrop" [isOpen]="isModalOpen" (ionModalDidDismiss)="onModalClose()">
  <ng-template>
      <ion-card class="popup-block__wrap">
          <ion-img class="popup-block__close" (click)="onClosed()" src="assets/images/close.svg"></ion-img>

          <ion-card-content class="popup-block__content" [ngClass]="className">
              <ng-container *ngIf="iconName">
                  <ion-img class="popup-block__icon" [src]="'assets/images/'+iconName"></ion-img>
              </ng-container>

              <ng-container *ngIf="headerText">
                  <ion-label class="popup-block__title h1" color="black">{{ headerText }}</ion-label>
              </ng-container>

              <ng-container *ngIf="descriptionText">
                  <ion-card-content class="popup-block__description" [ngStyle]="{'max-width': descriptionWidth}">
                      {{ descriptionText }}
                  </ion-card-content>
              </ng-container>

              <ng-content></ng-content>
          </ion-card-content>

          <ion-card-content class="popup-block__footer" *ngIf="cancelButtonText || nextButtonText">
              <app-button *ngIf="cancelButtonText" [buttonText]="cancelButtonText" color="primary" (buttonClick)="onCancelled()"></app-button>
              <app-button *ngIf="nextButtonText" [disabled]="disableNextButton" [buttonText]="nextButtonText" color="success" (buttonClick)="onNextClicked()"></app-button>
          </ion-card-content>
      </ion-card>
  </ng-template>
</ion-modal>