<ion-card class="calendar-block ion-no-padding ion-no-margin" [ngClass]="{'has-value':(pickerType==='range' && selectedDates?.length && (selectedDates[0] || selectedDates[1]))||(pickerType==='date' && selectedDates), 'is-clear': showReset}">
    <ion-card-content>
        <ion-label *ngIf="showHeadbar">{{showHeadbar}}</ion-label>
        <mbsc-datepicker
            [inputTyping]="false"
            [controls]="['calendar']"
            [(ngModel)]="selectedDates"
            [select]="pickerType"
            [max]="maxDateValue"
            [min]="minValue"
            [disabled]="disabled"
            [colors]="colors"
            [maxRange]="maxRange"
            [minRange]="minRange"
            [dateFormat]="displayDateFormat"
            [showRangeLabels]="showRangeLabels"
            rangeStartLabel="From :"
            rangeEndLabel="To :"
            [placeholder]="placeHolder"
            [invalid]="inValidRange"
            (onClose)="filter()"
            [focusOnClose]="false"
            defaultValue= null
            [touchUi]="false">
        </mbsc-datepicker>
        <ion-icon *ngIf="((pickerType==='range' && selectedDates?.length && selectedDates[0])||(pickerType==='date' && selectedDates)) && showReset" (click)="selectedDates=null;filter()" name="close-outline"></ion-icon>
    </ion-card-content>
  <ion-note *ngIf="isInvalid" color="danger">{{errorMessage}}</ion-note>
</ion-card>
