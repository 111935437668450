import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Ticket } from 'src/app/core/models/ticket.model';

@Component({
  selector: 'app-ticket-list-item',
  templateUrl: './ticket-list-item.component.html',
  styleUrls: ['./ticket-list-item.component.scss'],
})
export class TicketListItemComponent {
  @Input() ticketList: Ticket[];

  @Output() ticketClick = new EventEmitter();

  public checkClassColor(index: number,type: string){
    return this.ticketList[index].status==='open' && this.ticketList[index].category===type ;
  }

  public openTicket(ticket: any) {
    this.ticketClick.emit(ticket);
  }
}
