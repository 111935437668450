<ion-card class='detail-form'>
  <ion-card-title *ngIf='header' color='black'>{{header}}</ion-card-title>
  <form *ngIf="(editorType==='CREATE' || memberDetail) && membershipList && memberForm; else loading"
        [formGroup]='memberForm'>
    <ion-row class='gutter-lg'>
      <ng-container *ngIf='ownerRequired'>
        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating' position='floating'>Member Number:</ion-label>
            <ion-input type='text' appRestrictSpace [noSpace]='true' restrictValue=' !@#$%^&*(){}[]\<>?/'
                       formControlName='ownerNumber' [readonly]='isValidOwnerNumber'
                       [disabled]='isValidOwnerNumber'></ion-input>
            <ion-note *ngIf="isTouched('ownerNumber')" slot='error'>You must enter a valid member number.</ion-note>
          </ion-item>
        </ion-col>
        <ion-col size='12' size-sm='6'>
          <ion-item class='input-checkbox detail-form__item' lines='none'>
            <ion-checkbox formControlName='membershipStatus'></ion-checkbox>
            <ion-label>Membership Active</ion-label>
          </ion-item>
        </ion-col>
      </ng-container>
      <ng-container *ngIf=' memberDetail && memberDetail.parentId '>
        <ion-col size='12' size-md='6'>
          <ion-item>
            <ion-label position='floating' position='floating'>Parent Member Number:</ion-label>
            <ion-input type='text' [value]='memberDetail.parentId' disabled></ion-input>
          </ion-item>
        </ion-col>
      </ng-container>
    </ion-row>
    <ion-row class='gutter-lg'>
      <ion-col size='12' size-md='6'>
        <ion-item>
          <ion-label position='floating' position='floating'>First Name:</ion-label>
          <ion-input type='text' appRestrictSpace
                     formControlName='firstName'></ion-input>
          <ion-note *ngIf="isTouched('firstName')" slot='error'>You must enter a valid first name.</ion-note>
        </ion-item>
      </ion-col>
      <ion-col size='12' size-md='6'>
        <ion-item>
          <ion-label position='floating'>Last Name:</ion-label>
          <ion-input appRestrictSpace type='text'
                     formControlName='lastName'></ion-input>
          <ion-note *ngIf="isTouched('lastName')" slot='error'>You must enter a valid last name.</ion-note>
        </ion-item>
      </ion-col>

      <ion-col size='12' size-md='6'>
        <ion-item>
          <ion-label position='floating'>Address:</ion-label>
          <ion-input appRestrictSpace type='text'
                     formControlName='address'></ion-input>
          <ion-note *ngIf="isTouched('address')" slot='error'>You must enter a valid address.</ion-note>
        </ion-item>
      </ion-col>

      <ion-col size='12' size-md='6'>
        <ion-item>
          <ion-label position='floating'>Address 2:</ion-label>
          <ion-input appRestrictSpace type='text'
                     formControlName='address2'></ion-input>
          <ion-note *ngIf="isTouched('address')" slot='error'>You must enter a valid address.</ion-note>
        </ion-item>
      </ion-col>

      <ion-col size='12' size-md='6'>
        <ion-item>
          <ion-label position='floating'>City:</ion-label>
          <ion-input appRestrictSpace type='text' formControlName='city'></ion-input>
          <ion-note *ngIf="isTouched('city')" slot='error'>You must enter a valid city.</ion-note>
        </ion-item>
      </ion-col>
      <ion-col size='12' size-md='6'>
        <ion-row class='gutter-lg'>
          <ion-col size='12' size-sm='6'>
            <ion-item>
              <ion-label position='floating'>State:</ion-label>
              <ion-input appRestrictSpace type='text' formControlName='state'></ion-input>
              <ion-note *ngIf="isTouched('state')" slot='error'>You must enter a state.</ion-note>
            </ion-item>
          </ion-col>
          <ion-col size='12' size-sm='6'>
            <ion-item>
              <ion-label position='floating'>Zipcode:</ion-label>
              <ion-input type='text' maxlength='6' alphaNumeric formControlName='postalCode'></ion-input>
              <ion-note *ngIf="isTouched('postalCode')" slot='error'>You must enter a valid zipcode.</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-col>

      <ion-col size='12' size-md='6'>
        <ion-item>
          <ion-label position='floating'>Phone 1:</ion-label>
          <ion-input type='text' formatType='phone' maxlength='12' numberOnly
                     formControlName='phone1'></ion-input>
          <ion-note *ngIf="isTouched('phone1')" slot='error'>You must enter a valid phone number.</ion-note>
        </ion-item>
      </ion-col>
      <ion-col size='12' size-md='6'>
        <ion-item counter='true'>
          <ion-label position='floating'>Phone 1 Notes:</ion-label>
          <ion-input appRestrictSpace formControlName='phone1Notes'
                     maxlength='100' type='text'
                     placeholder='example: when should this number be used'></ion-input>
        </ion-item>
      </ion-col>

      <ion-col size='12' size-md='6'>
        <ion-item>
          <ion-label position='floating'>Phone 2:</ion-label>
          <ion-input type='text' numberOnly formatType='phone' formControlName='phone2' maxlength='12'></ion-input>
          <ion-note *ngIf="isTouched('phone2')" slot='error'>You must enter a valid phone number.</ion-note>
        </ion-item>
      </ion-col>
      <ion-col size='12' size-md='6'>
        <ion-item counter='true'>
          <ion-label position='floating'>Phone 2 Notes:</ion-label>
          <ion-input appRestrictSpace formControlName='phone2Notes'
                     maxlength='100' type='text'
                     placeholder='example: when should this number be used'></ion-input>
        </ion-item>
      </ion-col>


      <ion-col size='12' size-md='6'>
        <ion-item>
          <ion-label position='floating'>Email:</ion-label>
          <ion-input type='email' formControlName='email'></ion-input>
          <ion-note *ngIf="isTouched('email')" slot='error'>You must enter a valid Email.</ion-note>
        </ion-item>
      </ion-col>
      <ion-col size='12' size-md='6'>
        <ion-row>
          <ng-container *ngIf='utils.isFDRole()'>
            <ion-col size='12' size-sm='6'>
              <ion-item class='input-checkbox detail-form__item' lines='none'>
                <ion-checkbox formControlName='nineDayBuy'></ion-checkbox>
                <ion-label>9 Day Buy</ion-label>
              </ion-item>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-col>
    </ion-row>

    <ng-container *ngIf='!memberDetail || ( memberDetail && !memberDetail.parentId )'>
      <ion-row>
        <ion-col *ngIf='membershipList?.length'>
          <ion-item>
            <ion-label position='floating'>Membership Type:</ion-label>
            <ion-select formControlName='ownerSubType' (ionChange)='membershipChange($event)'>
              <ion-select-option *ngFor='let membership of membershipList'
                                 value="{{ membership.displayName+'---'+membership._id }}">
                {{ membership.displayName }}
              </ion-select-option>
            </ion-select>
            <ion-note *ngIf="isTouched('ownerSubType')" slot='error' color='danger'>You must select a Membership.
            </ion-note>
          </ion-item>
        </ion-col>
        <ion-col *ngIf='!memberDetail._id && memberForm?.value?.ownerSubType'>
          <ion-item>
            <ion-label position='floating'>Membership Initiation Cost:</ion-label>
            <ion-input type='text' numberOnly [hasDecimal]='true' [limitAfterDecimal]='2'
                       formControlName='membershipCost'></ion-input>
            <ion-note *ngIf="isTouched('membershipCost')" slot='error'>You must enter a valid amount.</ion-note>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
      </ion-row>
    </ng-container>


    <ng-container *ngIf='memberDetail.storageSites?.length'>
      <ion-toolbar>
        <ion-card-title>
          <ion-text class='h4' color='black'> Storage Sites :</ion-text>
        </ion-card-title>
      </ion-toolbar>

      <ion-row class='detail-form__row'>
        <ion-col size='12' size-sm='6' size-xl='4' *ngFor='let storage of memberDetail.storageSites;let i = index'>
          <ion-card class='detail-form__address'>
            <ion-card-content>
              <ion-row *ngIf="utils.isFDRole()" class="ion-justify-content-end">
                <ion-col size="auto">
                  <ion-icon class="pointer" (click)="showConfirmPopup = true; storageDetails = storage" color="danger" name="trash-outline"></ion-icon>
                </ion-col>
              </ion-row>
              <ion-text><strong>Storage Name: </strong>{{storage?.unitName || '-' }}</ion-text>
              <ion-text><strong>License Plate: </strong>{{ storage.licensePlate || '-' }}</ion-text>
              <ion-text><strong>Make: </strong> {{ storage.rvType || '-' }}</ion-text>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ng-container>

    <ng-container *ngIf='showFeaturesList'>
      <ion-row>
        <ion-col size='12'>
          <ion-toolbar>
            <ion-card-title>
              <ion-text class='h4' color='black'>Yearly Feature's</ion-text>
            </ion-card-title>
          </ion-toolbar>
          <ion-card>
            <ion-card-content>
              <ion-grid>
                <ion-row>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>Storage Site 1 ($330):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasStorageSite1'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>Storage Site 2 ($430):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasStorageSite2'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>Storage Site 3 ($430):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasStorageSite3'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>Indoor Golf Cart Storage ($175):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasIndoorGolfCartStorage'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>Free Golf Cart Storage ($0):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasFreeGolfCartStorage'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>Outdoor Golf Cart Storage ($60):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasOutdoorGolfCartStorage'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>Mail Forwarding ($150):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasMailForwarding'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>Mail Box ($120):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasMailbox'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>6x10 Indoor Storage ($475):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasSmallIndoorStorage'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>7x10 Indoor Storage ($425):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasMediumIndoorStorage'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size='12' size-md='6' size-lg='6' size-xl='4'>
                    <ion-item>
                      <ion-label>10x12 Indoor Storage ($575):</ion-label>
                      <ion-toggle [disabled]='!utils.isFDRole()' formControlName='hasLargeIndoorStorage'
                                  color='success'></ion-toggle>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ng-container>

    <app-note-list *ngIf='utils.isFDRole()' [id]='memberDetail?.ownerNumber' [noteList]='memberDetail?.notes'
                   (updatedNotes)='updateNotes($event)'></app-note-list>

    <ng-container *ngIf='showAddAssociateButton'>
      <ion-toolbar>
        <ng-container *ngIf='associateList?.length'>
          <ion-card-title class='ion-no-margin'>
            <ion-text class='h4' color='black'> Associates :</ion-text>
          </ion-card-title>
        </ng-container>
        <app-button slot='end' buttonText='Add Associate' *ngIf='hasRoleAccessForAssociate' color='dark'
                    (buttonClick)='openAssociatePopup( -1 )'></app-button>
      </ion-toolbar>
    </ng-container>


    <ng-container *ngIf='associateList?.length'>
      <ion-row class='detail-form__row'>
        <ion-col size='12' size-md='6' size-xl='4' *ngFor='let associate of associateList;let i = index'>
          <ion-card class='detail-form__address'>
            <ion-card-content>
              <ion-text><strong>Associate Member No.: </strong><span>{{associate?.ownerNumber || '-'}}</span></ion-text>
              <ion-text><strong>Type: </strong><span>{{associate?.associateType || '-'}}</span></ion-text>
              <ion-text>
                <strong>Name: </strong><span>{{(associate?.firstName || '') + ' ' + (associate?.lastName || '')}}</span>
              </ion-text>
              <ion-text><strong>Email: </strong> <span>{{associate?.email || '-'}}</span></ion-text>
              <ion-text><strong>City: </strong><span>{{associate?.city || '-'}}</span></ion-text>
              <ion-text><strong>State: </strong><span>{{associate?.state || '-'}}</span></ion-text>
              <ion-text><strong>Membership
                Status: </strong><span>{{ associate?.membershipStatus ? 'Active' : 'In-Active'}}</span></ion-text>
            </ion-card-content>
            <ng-container *ngIf="hasRoleAccess('MEMBER_SERVICE-ADD_ASSOCIATE') && showAssociatePaymentOptions">
              <ion-row class='ion-justify-content-between ion-margin-top'>
                <!-- <ion-col size="auto">
                    <ng-container *ngIf="associate._id && !associate?.membershipStatus">
                        <ion-card-content class="detail-form__action flex">
                            <ion-label>
                                <app-button buttonText="Complete Payment"
                                    [disabled]="!hasRoleAccess()" color="success"
                                    (buttonClick)="openPaymentMode( associate )"></app-button>
                            </ion-label>
                        </ion-card-content>
                    </ng-container>
                </ion-col> -->
                <ion-col size='auto'>
                  <ion-label class='detail-form__address-action'>
                    <ion-icon (click)='openAssociatePopup( i )' color='success' name='create-outline'></ion-icon>
                    <ion-icon (click)="confirmRemoveAssociate( i )" color="danger" name="trash-outline"></ion-icon>
                  </ion-label>
                </ion-col>
              </ion-row>
            </ng-container>
          </ion-card>
        </ion-col>
      </ion-row>
    </ng-container>

    <!-- <ng-container *ngIf="memberDetail && !memberDetail?.membershipStatus && showPayment && hasRoleAccess('MEMBER_SERVICE-DUES_PAYMENTS')">
        <ion-card-content class="detail-form__action flex">
            <ion-label>
                <app-button buttonText="Complete Payment"
                    [disabled]="!hasRoleAccess()" color="success"
                    (buttonClick)="openPaymentMode( memberDetail )"></app-button>
            </ion-label>
        </ion-card-content>
    </ng-container> -->
    <ion-card-content class='detail-form__action flex
            ion-justify-content-end'>
      <ion-label>
        <app-button buttonText='Cancel' *ngIf='showCancel' color='dark'
                    (buttonClick)='cancel()'></app-button>
        <app-button [buttonText]="ownerRequired?'Save':'Proceed'" [disabled]='!hasRoleAccess()'
                    color='success' (buttonClick)='save()'></app-button>
      </ion-label>
    </ion-card-content>
  </form>

  <ng-template #loading>
    <ion-row class='gutter-lg'>
      <ion-col size='12' size-md='6' *ngFor='let i of [].constructor(10)'>
        <app-skeleton type='bar' width='100%' height='48px' margin='0 0 28px'></app-skeleton>
      </ion-col>
      <ion-col size='12'>
        <app-skeleton type='bar' width='100%' height='48px' margin='0 0 28px'></app-skeleton>
      </ion-col>
      <ion-col size='12' size-md='6' *ngFor='let i of [].constructor(4)'>
        <app-skeleton type='bar' width='100%' height='48px' margin='0 0 28px'></app-skeleton>
      </ion-col>
    </ion-row>
    <ion-card-content class='detail-form__action flex ion-justify-content-between'>
      <app-skeleton type='bar' width='100px' height='48px'></app-skeleton>
      <app-skeleton type='bar' width='100px' height='48px'></app-skeleton>
    </ion-card-content>
  </ng-template>

  <app-popup [isModalOpen]='startMemberShipPayment' iconName=''
             nextButtonText='Pay Now' cancelButtonText='Cancel'
             headerText='Complete Payment: '
             (modalClosed)='initiatePayment( $event )'>
    <ion-row class='ion-justify-content-between'>
      <ion-col size='12' size-md='6'>
        <ion-item>
          <ion-label position='floating' for='payment'>Payment Mode</ion-label>
          <ion-select class='form-control' id='payment' [(ngModel)]='paymentMode'>
            <ion-select-option value='online'> Online</ion-select-option>
            <ion-select-option value='offline'> Pay in Cash</ion-select-option>
          </ion-select>
        </ion-item>

      </ion-col>
    </ion-row>
  </app-popup>


</ion-card>

<app-popup [isModalOpen]='associatePopup' (modalClosed)='associatePopup=false' iconName=''
           headerText='Associate Details: '>
  <app-associate-form #associateForm (cancelEvent)='associatePopup=false' [editIndex]='editingAssociateIndex'
                      [selectedAssociates]='associateList' (saveEvent)='addAssociateDetails($event)'>
  </app-associate-form>
</app-popup>


<app-popup [isModalOpen]="showConfirmPopup" (modalClosed)="onDeleteConfirmed($event)" cancelButtonText="Cancel"
  nextButtonText="Continue" headerText="Are you sure you want to clear this storage unit?">
</app-popup>
