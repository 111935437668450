/* eslint-disable @typescript-eslint/naming-convention */
export const ROLES = {
    NON_MEMBER: { name: 'Non Member', value: 'non-member' },
    MEMBER: { name: 'Member', value: 'member' },
    RESERVATION_BASIC: { name: 'Reservation Basic', value: 'reservation-basic' },
    RESERVATION_LEAD: { name: 'Reservation Lead', value: 'reservation-lead' },
    RESERVATION_MANAGER: { name: 'Reservation Manager', value: 'reservation-manager' },
    HOUSEKEEPING_BASIC: { name: 'Housekeeping Basic', value: 'housekeeping-basic' },
    HOUSEKEEPING_MANAGER: { name: 'Housekeeping Manager', value: 'housekeeping-manager' },
    MAINTENANCE_BASIC: { name: 'Maintenance Basic', value: 'maintenance-basic' },
    MAINTENANCE_ADMIN: { name: 'Maintenance Admin', value: 'maintenance-admin' },
    MAINTENANCE_MANAGER: { name: 'Maintenance Manager', value: 'maintenance-manager' },
    GROUNDS_BASIC: { name: 'Grounds Basic', value: 'grounds-basic' },
    GROUNDS_MANAGER: { name: 'Grounds Manager', value: 'grounds-manager' },
    MAILROOM: { name: 'Mailroom', value: 'mailroom' },
    SALES: { name: 'Sales', value: 'sales' },
    MEMBER_SERVICES: { name: 'Member Services', value: 'member-services' },
    ADMIN: { name: 'Admin', value: 'admin' },
};
