<ion-content class="map-block" [scrollX]="true" [scrollY]="true">
    <ion-item-group>
        <pinch-zoom
            [minScale]="1"
            [disablePan]="false"
            [limit-zoom]="10"
            [draggableImage]="false"
            [limitPan]="false">
            <img
                src="https://d1dvcmimg2pvsw.cloudfront.net/siteMap/hartranch.png"
                alt="image">
        </pinch-zoom>
        <ion-icon class="cross-icon" name="close-circle-outline"
            (click)="closed.next(true)"></ion-icon>
    </ion-item-group>
</ion-content>