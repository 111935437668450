/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { hasRoleAccess } from 'src/app/core/helpers/helpers';
import { Member } from 'src/app/core/models/member';
import { IMembership } from 'src/app/core/models/membership.model';
import { Note } from 'src/app/core/models/reservation';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { MembersService } from 'src/app/core/services/members.service';
import { UtilService } from 'src/app/core/services/util.service';
import { EmailValidator } from 'src/app/core/validators/email';
import { AssociateFormComponent } from '../associate-form/associate-form.component';
import { StorageMoveService } from 'src/app/core/services/storage-move.service';
import { StorageManagerService } from 'src/app/core/services/storage-manager.service';
import { Storage } from 'src/app/core/models/storage';

@Component({
    selector: 'app-member-detail-form',
    templateUrl: './member-detail-form.component.html',
    styleUrls: ['./member-detail-form.component.scss'],
})
export class MemberDetailFormComponent implements OnInit, OnChanges {

    @Input() memberDetail: Member = {} as Member;
    @Input() header: string;
    @Input() membershipList: IMembership[];
    @Input() editorType: 'CREATE'|'UPDATE'|'READ';
    @Input() showCancel: boolean = true;
    @Input() showPayment: boolean = true;
    @Input() ownerRequired: boolean = true;
    @Input() isRPI: boolean = false;
    @Input() showAssociatePaymentOptions: boolean = true;
    @Input() showAddAssociateButton: boolean = true;
    @Input() showFeaturesList: boolean = true;

    @Output() cancelEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() saveEvent: EventEmitter<Member> = new EventEmitter();
    @Output() refreshMemberData: EventEmitter< boolean > = new EventEmitter();

    @ViewChild( 'associateForm' ) associateForm: AssociateFormComponent;
    public showConfirmPopup = false;
    public storageDetails: Storage | null =  null;
    public userData: User;
    public editingAssociateIndex = -1;
    public associatePopup = false;
    public associateList: Member[] =[];
    maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString();
    public memberForm: FormGroup;
    prevValue = '';
    public paymentMode = 'online';
    startMemberShipPayment: boolean;
    public membershipCost: number;
    private loadingPayment: any;
    private selectedMember: Member;
    private loadingMemberCreatePayment: boolean;

    constructor(
        public alertController: AlertController,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private memberService: MembersService,
        private loadingController: LoadingController,
        private storageService: StorageManagerService,
        public utils: UtilService) {
        this.userData = this.authService.getUserData();
    }

    public hasRoleAccess( action: string = 'MEMBER_SERVICE-UPDATE_DETAILS' ) {
        return hasRoleAccess( action, this.userData?.roles);
    }

    public canChangeStatus( ) {
        return hasRoleAccess( 'MEMBER_SERVICE-CHANGE_STATUS', this.userData?.roles);
    }

    get hasRoleAccessForAssociate() {
        return hasRoleAccess('MEMBER_SERVICE-ADD_ASSOCIATE', this.userData?.roles);
    }

    get isValidOwnerNumber(): boolean{
        return  (!this.hasRoleAccess || (this.memberDetail?._id && this.memberDetail?.ownerNumber)) ? true : false;
    }

    ngOnInit() { }

    ngOnChanges(){
        if(((this.memberDetail) || this.editorType==='CREATE' ) && this.editorType){
            this.setupValidator();
            this.prevValue = (this.memberDetail?.ownerSubType||'') + '---' + (this.memberDetail?.ownerSubTypeId||'');
        }
        this.associateList = this.memberDetail?.associateDetails||[];
    }

    public cancel() {
        this.cancelEvent.emit(true);
    }

    public save(): void {
        this.memberForm.markAllAsTouched();

            const payload = {...this.memberForm.value, notes: this.memberDetail?.notes };
            if(this.memberDetail && this.memberDetail?._id ){
                // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/dot-notation
                payload['_id'] = this.memberDetail?._id;
                payload.ownerNumber = this.memberDetail?.ownerNumber || payload.ownerNumber;
            }
            if( this.memberForm.controls.ownerSubType.value ){
                const ownerSubtypeData = this.memberForm.controls.ownerSubType.value?.split('---');
                payload.ownerSubTypeId = ownerSubtypeData[1]||'';
                payload.ownerSubType = ownerSubtypeData[0]||'';
                this.memberForm.controls.ownerSubTypeId.setValue(ownerSubtypeData[1]||'');
            }
            if( this.isRPI ){
                if( this.memberForm.controls.email.value ){
                    this.memberForm.controls.email.setValidators([EmailValidator.isValid]);
                    this.memberForm.controls.email.updateValueAndValidity();
                    this.memberForm.markAllAsTouched();
                } else {
                    this.memberForm.controls.email.setValidators([]);
                    this.memberForm.controls.email.updateValueAndValidity();
                    this.memberForm.markAllAsTouched();
                }
            }
            if (this.memberForm.invalid) {
                return;
            } else {
                console.log('emitting save member event')
                this.saveEvent.emit({...payload, associateDetails: this.associateList});
            }
    }

    public async updateMembershipType( ) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Update',
            subHeader: '',
            message: 'Are you sure you want to change this member\'s membership?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        this.memberForm.controls.ownerSubType.setValue(this.prevValue);
                    }
                },
                {
                    text: 'Confirm Update',
                    handler: () => {
                        this.prevValue = this.memberForm.controls.ownerSubType.value;
                    }
                  }
            ],
        });
        await alert.present();
    }

    public isTouched(field: string): boolean{
        return this.memberForm.controls[field].touched;
    }

    public async initiatePayment( event: 'next'|'cancel'|'close' ){
        this.startMemberShipPayment = false;
        if( event === 'next' ){
            this.loadingPayment = await this.loadingController.create({
                message: 'Processing Payment...',
            });
            await this.loadingPayment.present();
            let timer = null;
            await this.memberService.startMembershipPayment( this.selectedMember, this.paymentMode).then(async res=>{
                if(res?.stripeSession?.url){
                    this.loadingMemberCreatePayment = false;
                    const popUp = window.open(res.stripeSession.url,'_blank');
                    timer = setInterval(()=>{
                        if (popUp.closed) {
                            if( timer ){
                            if( !this.loadingMemberCreatePayment ){
                                this.utils.showToast('Payment has been Cancelled!');
                            }
                            this.loadingPayment.dismiss();
                            clearInterval(timer);
                          }
                        }
                    }, 500);

                    if (popUp == null || typeof(popUp)=='undefined') {
                        alert('Please disable your pop-up blocker and try again.');
                        if( timer ){
                            clearInterval(timer);
                        }
                        this.loadingPayment.dismiss();
                    }
                    else {
                        popUp.focus();
                    }
                    window.addEventListener('message', (data) => {
                        if(data.origin === window.location.origin){

                            if (data.data.session_id && data.data.status.toLowerCase() === 'success' && !this.loadingMemberCreatePayment){
                                if( timer ){
                                    clearInterval(timer);
                                }
                                this.onSuccessfulPayment.apply(this, [data]);
                                this.loadingMemberCreatePayment = true;
                            } else if (data.data.session_id && data.data.status.toLowerCase() === 'fail' && !this.loadingMemberCreatePayment){
                                if( timer ){
                                    clearInterval(timer);
                                }
                                this.loadingPayment.dismiss();
                                this.loadingMemberCreatePayment = false;
                            }
                        } else {
                            if( timer ){
                                clearInterval(timer);
                            }
                            this.loadingPayment.dismiss();
                            return;
                        }
                    });
                } else {
                    if( res.modifiedCount ){
                        this.utils.showToast('Payment has been Completed Successfully');
                    }
                    this.loadingPayment.dismiss();
                    this.refreshMemberData.next( true );
                }
            })
            .catch((err)=>{
                if( timer ){
                    clearInterval(timer);
                }
                this.loadingPayment.dismiss();
                try{
                  const errMessage = err?.error ? JSON.parse( err?.error )?.message : 'Something went wrong';
                  this.utils.showToast( errMessage );
                }catch( error ){
                  this.utils.showToast( err?.error ? err?.error : 'Something went wrong' );
                }
            });
        }
    }

    public openAssociatePopup( editingIndex: number ){
        this.associatePopup=true;
        this.editingAssociateIndex = editingIndex;
        if( editingIndex >= 0 ){
            this.associateForm.initForm( this.associateList[ editingIndex ] );
        } else {
            this.associateForm.initForm( {} as Member );
        }
    }

    public addAssociateDetails(associate: Member){
        if( this.editingAssociateIndex >= 0 ){
            this.associateList[this.editingAssociateIndex] = associate;
        }else{
            this.associateList.push(associate);
        }
        this.editingAssociateIndex = -1;
        this.associatePopup=false;
    }

    public async confirmRemoveAssociate(index: number) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Delete',
            subHeader: '',
            message: 'Are you sure you want to delete this associate?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => { }
                },
                {
                    text: 'Delete',
                    handler: () => {
                        this.associateList.splice(index, 1);
                    }
                  }
            ],
        });
        await alert.present();
    }

    updateNotes( notes: Note[] ){
        this.memberDetail.notes = notes || [];
    }

    public openPaymentMode( memberDetail: Member){
        this.startMemberShipPayment = true;
        this.selectedMember = memberDetail;
    }

    public membershipChange( event: any ){
        if( event.detail.value && event.detail.value.split('---').length ){
            const membershipDetails = event.detail.value.split('---');
            if( membershipDetails.length ==2  && !this.memberDetail?._id ){
                const selectedMembershipDetail = this.membershipList.filter( membership => ( (membership.displayName == membershipDetails[0]) && (membership._id.toString() == membershipDetails[1]) ))[0];
                this.memberForm.controls.membershipCost.setValue( selectedMembershipDetail?.initiationCost||0 );
            }
        }
    }

    public async clear(storage: Storage){
      const loading = await this.loadingController.create({
        message: 'Clearing Storage...',
      });
      await loading.present();
      try{
        const storageDetails: Storage = {
          _id: storage._id,
          licensePlate: '',
          email: '',
          unitName: storage?.unitName,
          name: '',
          rvType: '',
          sizeGuide: '',
          memberNo: '',
          notes: '',
          type: '',
          contractSigned: false,
          paid: false,
          hasKeys: false,
        };
        await this.storageService.updateStorage(storageDetails);
        this.refreshMemberData.next(true);
      }catch(err){
        try{
          const errMessage = err?.error ? JSON.parse( err?.error )?.message : 'Something went wrong';
          this.utils.showToast( errMessage );
        }catch( error ){
          this.utils.showToast( err?.error ? err?.error : 'Something went wrong' );
        }
      }finally{
        loading.dismiss();
      }
    }

    public onDeleteConfirmed(event){
      this.showConfirmPopup = false;
      if (event === 'next') {
            this.clear(this.storageDetails);
      }
      this.storageDetails = null;
    }

    private setupValidator() {
        this.memberForm = this.formBuilder.group({
            ownerNumber: [{
                value: this.memberDetail?.ownerNumber || '',
                disabled: (!this.hasRoleAccess || (this.memberDetail?._id && this.memberDetail?.ownerNumber && !this.isRPI)),
            }],

            firstName: [{
                value: this.memberDetail?.firstName || '',
                disabled: (!this.hasRoleAccess('MEMBER_SERVICE-CHANGE_NAME') && this.ownerRequired ),
            }, [Validators.required, Validators.minLength(2)]],

            lastName: [
                {
                    value: this.memberDetail?.lastName || '',
                    disabled: (!this.hasRoleAccess('MEMBER_SERVICE-CHANGE_NAME') && this.ownerRequired ),
                },
                [Validators.required, Validators.minLength(2)]
            ],
            address: [
                {
                    value: this.memberDetail?.address || '',
                    disabled: !this.hasRoleAccess(),
                },
                this.isRPI ? [Validators.minLength(2)] : [Validators.required, Validators.minLength(2)]
            ],
            address2: [
                {
                    value: this.memberDetail?.address2 || '',
                    disabled: !this.hasRoleAccess,
                },
                [Validators.minLength(2)]
            ],
            city: [
                {
                    value: this.memberDetail?.city || '',
                    disabled: !this.hasRoleAccess(),
                },
                this.isRPI ? [Validators.minLength(2)] : [Validators.required, Validators.minLength(2)]
            ],
            state: [
                {
                    value: this.memberDetail?.state || '',
                    disabled: !this.hasRoleAccess(),
                },
                this.isRPI ? [Validators.minLength(2)] : [Validators.required, Validators.minLength(2)]
            ],
            postalCode: [
                {
                    value: this.memberDetail?.postalCode || '',
                    disabled: !this.hasRoleAccess(),
                },
                this.isRPI ? [Validators.minLength(5)] : [
                  Validators.required,
                  Validators.minLength(5),
                ]
            ],
            phone1: [
                {
                    value: this.memberDetail?.phone1 || '',
                    disabled: !this.hasRoleAccess(),
                },
                this.isRPI ? [Validators.minLength(12)] : [Validators.required, Validators.minLength(12)]
            ],
            phone1Notes: [{
                value: this.memberDetail?.phone1Notes,
                disabled: !this.hasRoleAccess(),
            }],
            membershipStatus: [{
                value: this.memberDetail?.membershipStatus || false,
                disabled: !this.canChangeStatus(),
            }],
            phone2: [
                {
                    value: this.memberDetail?.phone2 || '',
                    disabled: !this.hasRoleAccess(),
                },
                Validators.minLength(12)
            ],
            phone2Notes: [{
                value: this.memberDetail?.phone2Notes,
                disabled: !this.hasRoleAccess(),
            }],
            email: [
                {
                    value: this.memberDetail?.email || '',
                    disabled: !this.hasRoleAccess(),
                },
                this.isRPI ? [] : [Validators.required, EmailValidator.isValid]
            ],
            nineDayBuy: [
                {
                    value: this.memberDetail?.nineDayBuy||false,
                    disabled: (!this.hasRoleAccess() ),
                }
             ],
          hasStorageSite1: [
            {
              value: this.memberDetail?.hasStorageSite1||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasStorageSite2: [
            {
              value: this.memberDetail?.hasStorageSite2||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasStorageSite3: [
            {
              value: this.memberDetail?.hasStorageSite3||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasIndoorGolfCartStorage: [
            {
              value: this.memberDetail?.hasIndoorGolfCartStorage||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasFreeGolfCartStorage: [
            {
              value: this.memberDetail?.hasFreeGolfCartStorage||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasOutdoorGolfCartStorage: [
            {
              value: this.memberDetail?.hasOutdoorGolfCartStorage||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasMailForwarding: [
            {
              value: this.memberDetail?.hasMailForwarding||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasMailbox: [
            {
              value: this.memberDetail?.hasMailbox||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasSmallIndoorStorage: [
            {
              value: this.memberDetail?.hasSmallIndoorStorage||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasMediumIndoorStorage: [
            {
              value: this.memberDetail?.hasMediumIndoorStorage||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
          hasLargeIndoorStorage: [
            {
              value: this.memberDetail?.hasLargeIndoorStorage||false,
              disabled: (!this.hasRoleAccess() ),
            }
          ],
            birthdate: [
                {
                    value: this.memberDetail?.birthdate || null,
                    disabled: !this.hasRoleAccess(),
                }
            ],
            ownerSubType: new FormControl({
                value: this.memberDetail?.ownerSubTypeId?this.memberDetail?.ownerSubType+'---'+this.memberDetail?.ownerSubTypeId : '',
                disabled: !this.hasRoleAccess('MEMBER_SERVICE-UPDATE_MEMBERSHIPTYPE'),
            }),
            ownerSubTypeId: new FormControl(this.memberDetail?.ownerSubTypeId || '',),
            membershipCost: new FormControl( {
                value: this.memberDetail?._id ? 0 : null,
                disabled: this.memberDetail?._id
            }  )
        });

        if( !this.memberDetail._id && this.ownerRequired ){
            this.memberForm.controls.membershipCost.setValidators([Validators.required]);
            this.memberForm.controls.membershipCost.updateValueAndValidity();
        }
        if( this.ownerRequired ){
            this.memberForm.controls.ownerNumber.setValidators([Validators.required]);
            this.memberForm.controls.ownerNumber.updateValueAndValidity();
        }

        if (this.membershipList?.length && this.hasRoleAccess('MEMBER_SERVICE-UPDATE_MEMBERSHIPTYPE') ) {
            this.memberForm.controls.ownerSubType.setValidators([Validators.required]);
            this.memberForm.controls.ownerSubTypeId.setValidators([Validators.required]);
            this.memberForm.controls.ownerSubType.updateValueAndValidity();
            this.memberForm.controls.ownerSubTypeId.updateValueAndValidity();
            if(this.memberDetail && this.memberDetail?._id ){
                this.memberForm.controls.ownerSubType.valueChanges.subscribe(async (val)=>{
                    if(this.prevValue !== val ){
                        await this.updateMembershipType( );
                    }
                });
            }
        }
    }

    private async onSuccessfulPayment(data: any){
        this.loadingPayment.dismiss();
        if(data.data.session_id && data.data.status.toLowerCase()==='success'){
            this.utils.showToast('Payment has been Completed Successfully');
            this.refreshMemberData.next( true );
        }
    }
}
