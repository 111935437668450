import { Injectable } from '@angular/core';
import * as Realm from 'realm-web';
import { APP_ID } from '../../realm';
import { EmailData } from '../models/email';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RealmService {
    public realmApp;
    public readonly appID = environment.constants.APP_ID;

    constructor() {
        this.realmApp = new Realm.App(this.appID);
    }

    public getCollection(name: string) {
        const mongodb = this.realmApp.currentUser.mongoClient('mongodb-atlas');
        // @ts-ignore
        return mongodb.db('hart-ranch').collection<any>(name);
    }

    // Get a valid Realm user access token to authenticate requests
    public async getValidAccessToken(): Promise<string> {
        if (!this.realmApp.currentUser) {
            //await this.realmApp.logIn(Realm.Credentials.anonymous());
        } else {
            await this.realmApp.currentUser.refreshCustomData();
        }

        // Get a valid access token for the current user
        localStorage.setItem('token', this.realmApp.currentUser.accessToken);
        localStorage.setItem('refreshToken', this.realmApp.currentUser.refreshToken);
        return this.realmApp.currentUser.accessToken;
    }

    public registerUser(email: string, password: string) {
        return this.realmApp.emailPasswordAuth.registerUser({ email, password });
    }

    public confirmUser(token: string, tokenId: string) {
        return this.realmApp.emailPasswordAuth.confirmUser({ token, tokenId });
    }

    public sendResetPasswordEmail(email: string) {
        return this.realmApp.emailPasswordAuth.sendResetPasswordEmail({ email });
    }

    public resetPassword(password: string, token: string, tokenId: string) {
        return this.realmApp.emailPasswordAuth.resetPassword({ password, token, tokenId });
    }

    public disableUser(userId: string, authToken: string, userData: User): Promise<any> {
        return this.realmApp.currentUser.functions.disableUser({ userId, authToken, userData });
    }

    public enableUser(userId: string, authToken: string): Promise<any> {
        return this.realmApp.currentUser.functions.enableUser({ userId, authToken });
    }

    public async getAdminAuthToken() {
        return this.realmApp.currentUser.functions.getAdminAuthToken();
    }

    public sendEmail(emailData: EmailData){
        return this.realmApp.currentUser.functions.sendEmail({emailData});
    }
}
