

<ion-card class="use-credit popup-block__wrap">
  <form [formGroup]="creditForm">
    <ion-card-content>
        <ion-label class="use-credit__title h2" color="black">Do you want to use credits?</ion-label>
    </ion-card-content>
    <ion-item>
        <ion-label position="floating">Enter Amount to be used:</ion-label>
        <ion-input
        formControlName="credits"
        placeholder="Enter Credit Amount"
        maxlength="9"
        type="text"
        numberOnly
        [hasDecimal]="true"
        [limitAfterDecimal]="2"></ion-input>
      </ion-item>
      <ion-note class="use-credit__error ion-margin-vertical flex" *ngIf="creditForm.get('credits').hasError('max')" slot="error"> You can use maximum of {{ maxCreditsThatCanBeUsed | currency }}</ion-note>
      <ion-card-content class="popup-block__footer" >
        <app-button buttonText="Use Credits" [disabled]="creditForm.invalid" color="success" (buttonClick)="addCharges()"></app-button>
    </ion-card-content>
  </form>
</ion-card>


